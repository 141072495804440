export interface Theme {
  header: string;
  name: string;
  logo?: string;
  logoSquare?: string;
  primaryColor: string;
  agent?: ThemeAgent;
  redirectUrl?: string;
}
export interface ThemeAgent {
  header: string;
  name: string;
  phone: string;
  phoneD2C: string;
  hours: string;
  email: string;
}

export class DefaultDirectTheme {
  header: string = 'Support/Contact Information';
  name: string = 'default';
  // logo: string = 'nwide_logo.png';
  // logoSquare: string = 'nwide_logo_square.png';
  primaryColor: string = '#0057b8';
  agent: any = {
    header: '',
    name: 'Arch Customer Support',
    phone: '888-910-6553',
    phoneD2C: '(888) 910-6553',
    email: 'customerservice@archinsurance.com',
  };
  constructor() {}
}
