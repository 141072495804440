export class PaymentScheduleMock {
  public static data = {
    responseTransactionSchedule: {
      RequestResponse: {
        ProcessStatusFlag: 'COMPLETED',
        RequestSeq: '205346',
        SourceSystemRequestNo: '7851251523103544',
        SuccessFlag: 'SUCCESS',
      },
      TransactionResponse: {
        PolicyEffectiveDate: '2022-12-08-05:00',
        PolicyExpirationDate: '2023-12-08-05:00',
        PolicyNo: 'POLACNTS',
        PreviousSourceTransactionRequestNo: '123',
        ScheduleAsOfDate: '2022-12-08-05:00',
        TransactionDetail: [
          {
            PaymentPlan: '25DP9',
            InstallmentScheduleSummarybyDueDate: [
              {
                BillDueDate: '2023-02-08-05:00',
                BillSendDate: '2023-01-09-05:00',
                InstallmentSequenceNo: '1',
                TransactionReceivableSummary: [
                  {
                    Balance: 87.05,
                    CommissionAmount: 8.71,
                    CommissionPercent: 10.0,
                    InstallmentNo: '1',
                    PolicyEffectiveDate: '2022-12-08-05:00',
                    PolicyNo: 'POLACNTS',
                    ReceivableAmount: 87.05,
                    ReceivableCategory: 'PREMIUM',
                    ReceivableCode: 'PREMIUM',
                  },
                ],
              },
              {
                BillDueDate: '2023-05-08-04:00',
                BillSendDate: '2023-04-07-04:00',
                InstallmentSequenceNo: '4',
                TransactionReceivableSummary: [
                  {
                    Balance: 87.05,
                    CommissionAmount: 8.71,
                    CommissionPercent: 10.0,
                    InstallmentNo: '4',
                    PolicyEffectiveDate: '2022-12-08-05:00',
                    PolicyNo: 'POLACNTS',
                    ReceivableAmount: 87.05,
                    ReceivableCategory: 'PREMIUM',
                    ReceivableCode: 'PREMIUM',
                  },
                ],
              },
              {
                BillDueDate: '2023-04-08-04:00',
                BillSendDate: '2023-03-09-05:00',
                InstallmentSequenceNo: '3',
                TransactionReceivableSummary: [
                  {
                    Balance: 87.05,
                    CommissionAmount: 8.71,
                    CommissionPercent: 10.0,
                    InstallmentNo: '3',
                    PolicyEffectiveDate: '2022-12-08-05:00',
                    PolicyNo: 'POLACNTS',
                    ReceivableAmount: 87.05,
                    ReceivableCategory: 'PREMIUM',
                    ReceivableCode: 'PREMIUM',
                  },
                ],
              },
              {
                BillDueDate: '2023-07-08-04:00',
                BillSendDate: '2023-06-08-04:00',
                InstallmentSequenceNo: '6',
                TransactionReceivableSummary: [
                  {
                    Balance: 87.05,
                    CommissionAmount: 8.71,
                    CommissionPercent: 10.0,
                    InstallmentNo: '6',
                    PolicyEffectiveDate: '2022-12-08-05:00',
                    PolicyNo: 'POLACNTS',
                    ReceivableAmount: 87.05,
                    ReceivableCategory: 'PREMIUM',
                    ReceivableCode: 'PREMIUM',
                  },
                ],
              },
              {
                BillDueDate: '2023-01-08-05:00',
                BillSendDate: '2022-12-09-05:00',
                InstallmentSequenceNo: '0',
                TransactionReceivableSummary: [
                  {
                    Balance: 261.24,
                    CommissionAmount: 26.08,
                    CommissionPercent: 10.0,
                    InstallmentNo: '0',
                    PolicyEffectiveDate: '2022-12-08-05:00',
                    PolicyNo: 'POLACNTS',
                    ReceivableAmount: 261.24,
                    ReceivableCategory: 'PREMIUM',
                    ReceivableCode: 'PREMIUM',
                  },
                ],
              },
              {
                BillDueDate: '2023-08-08-04:00',
                BillSendDate: '2023-07-07-04:00',
                InstallmentSequenceNo: '7',
                TransactionReceivableSummary: [
                  {
                    Balance: 87.05,
                    CommissionAmount: 8.71,
                    CommissionPercent: 10.0,
                    InstallmentNo: '7',
                    PolicyEffectiveDate: '2022-12-08-05:00',
                    PolicyNo: 'POLACNTS',
                    ReceivableAmount: 87.05,
                    ReceivableCategory: 'PREMIUM',
                    ReceivableCode: 'PREMIUM',
                  },
                ],
              },
              {
                BillDueDate: '2023-06-08-04:00',
                BillSendDate: '2023-05-09-04:00',
                InstallmentSequenceNo: '5',
                TransactionReceivableSummary: [
                  {
                    Balance: 87.05,
                    CommissionAmount: 8.71,
                    CommissionPercent: 10.0,
                    InstallmentNo: '5',
                    PolicyEffectiveDate: '2022-12-08-05:00',
                    PolicyNo: 'POLACNTS',
                    ReceivableAmount: 87.05,
                    ReceivableCategory: 'PREMIUM',
                    ReceivableCode: 'PREMIUM',
                  },
                ],
              },
              {
                BillDueDate: '2023-09-08-04:00',
                BillSendDate: '2023-08-09-04:00',
                InstallmentSequenceNo: '8',
                TransactionReceivableSummary: [
                  {
                    Balance: 87.05,
                    CommissionAmount: 8.71,
                    CommissionPercent: 10.0,
                    InstallmentNo: '8',
                    PolicyEffectiveDate: '2022-12-08-05:00',
                    PolicyNo: 'POLACNTS',
                    ReceivableAmount: 87.05,
                    ReceivableCategory: 'PREMIUM',
                    ReceivableCode: 'PREMIUM',
                  },
                ],
              },
              {
                BillDueDate: '2023-03-08-05:00',
                BillSendDate: '2023-02-06-05:00',
                InstallmentSequenceNo: '2',
                TransactionReceivableSummary: [
                  {
                    Balance: 87.05,
                    CommissionAmount: 8.71,
                    CommissionPercent: 10.0,
                    InstallmentNo: '2',
                    PolicyEffectiveDate: '2022-12-08-05:00',
                    PolicyNo: 'POLACNTS',
                    ReceivableAmount: 87.05,
                    ReceivableCategory: 'PREMIUM',
                    ReceivableCode: 'PREMIUM',
                  },
                ],
              },
              {
                BillDueDate: '2023-10-08-04:00',
                BillSendDate: '2023-09-08-04:00',
                InstallmentSequenceNo: '9',
                TransactionReceivableSummary: [
                  {
                    Balance: 87.36,
                    CommissionAmount: 8.74,
                    CommissionPercent: 10.0,
                    InstallmentNo: '9',
                    PolicyEffectiveDate: '2022-12-08-05:00',
                    PolicyNo: 'POLACNTS',
                    ReceivableAmount: 87.36,
                    ReceivableCategory: 'PREMIUM',
                    ReceivableCode: 'PREMIUM',
                  },
                ],
              },
            ],
            Messages: [
              {
                Code: 'WHATIF-Revert',
                Description: 'Reverting the committed changes for what If transactions.',
              },
            ],
          },
        ],
        WhatIfTransaction: 'WHAT_IF_NEW',
      },
    },
  };
}
