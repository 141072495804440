import version from '../../package.json';

export const environment = {
  production: true,
  hmr: false,
  version: version.version + '-int',
  envName: 'int',
  environmentName: 'INT',
  origins: ['https://billing-portal-int.apps.aks-nonprod.archinsurance.com'],
  appUrl: 'https://billing-portal-int.apps.aks-nonprod.archinsurance.com',
  AIP_AGENCY_ROLE: 'AIGI-ROLE-DIGAIP-AGENCY',
  AIP_ADMIN_ROLE: 'ADMIN',
  aipHostPort: 'http://aigi-drvjbss001:8080/',
  billingPortalApiHost: 'https://billing-portal-int.apps.aks-nonprod.archinsurance.com/api/',
  billingPortalConsumerApiHost: 'https://billing-portal-int.apps.aks-nonprod.archinsurance.com/api-con/',
  bopQuoteEndPoint: 'api/quote',

  chaseOrigin: {
    version2: {
      origin: 'https://www.chasepaymentechhostedpay-var.com',
      iframeOrigin: `https://www.chasepaymentechhostedpay-var.com`,
    },
    version3: {
      origin: 'https://chase-var.hostedpaymentservice.net',
      iframeOrigin: `https://chase-var.hostedpaymentservice.net`,
    },
  },

  aipHostPortMenu: 'https://aip-authorization-ms-int.apps.aks-nonprod.archinsurance.com/',
  chaseCallBackUrl: 'https://billing-portal-int.apps.aks-nonprod.archinsurance.com/success/',
  hostedChaseCallBackUrl: 'https://billing-portal-int.apps.aks-nonprod.archinsurance.com/hosted/success/',
  aipHostPortAdmin: 'https://aip-admin-api-int.apps.aks-nonprod.archinsurance.com/',
  aipHostPortBase: 'https://aip-base-api-int.apps.aks-nonprod.archinsurance.com',
  aipHostPort1: 'https://aip-base-api-int.apps.aks-nonprod.archinsurance.com/aip-base-api/aip-base-api',
  aipHostPort2: 'https://aip-authorization-ms-int.apps.aks-nonprod.archinsurance.com',
  pgrRedirectUrl: 'aHR0cHM6Ly9xYS5idXNpbmVzc3F1b3RlLnByb2dyZXNzaXZlY29tbWVyY2lhbC5jb20vYXJjaGNvbmZpcm1hdGlvbg==', //default redirectUrl for Progressive.

  //ROLE
  internal: 'ARCH',
  external: 'AGENCY',
  admin: 'ADMIN',
  internalUser: 'Internal',
  externalUser: 'External',
  INTERNAL_EMAIL_DOMAIN: [
    'archinsurance.com',
    'archgroup.com',
    'archcapservices.com',
    'archgso.com',
    'corp.archcapservices.com',
  ],
  SKIP_PORTALS_INTERNAL_USER: ['AIGI-BasePortal'],
  AUTO_PORTALS_INTERNAL_USER: [{ 'AIGI-BasePortal': 'AIGI-ROLE-DIGAIP-ARCH-UNDERWRITER' }],
  LAUNCHDARKLY_CLIENTID: '638e3b9476b58811410188ee',
};
