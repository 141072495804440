import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard {
  private authenticated: boolean;

  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.authenticated.subscribe((i) => (this.authenticated = i));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // console.log('state from canActivate', state);
    return this.authenticationService.loaded
      .pipe(filter((done) => done))
      .pipe(tap(() => this.authenticated || this.authenticationService.login(location.href)))
      .pipe(map(() => this.authenticated));
  }
}
