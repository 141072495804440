import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { EmptyShell } from '@app/empty-shell/empty-shell.service';
import { ShellDirect } from '@app/direct/shell/shell.service';
import { ShellRenewal } from '@app/renewal/shell/shell.service';
import { ShellHosted } from './hosted/shell/shell.service';

const routes: Routes = [
  Shell.childRoutes([{ path: 'about', loadChildren: () => import('./about/about.module').then((m) => m.AboutModule) }]),
  Shell.childRoutes([
    { path: 'profile', loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule) },
  ]),
  ShellDirect.childRoutes([
    { path: 'direct', loadChildren: () => import('./direct/direct.module').then((m) => m.DirectModule) },
  ]),
  ShellRenewal.childRoutes([
    { path: 'renewal', loadChildren: () => import('./renewal/renewal.module').then((m) => m.RenewalModule) },
  ]),
  EmptyShell.childRoutes([
    { path: 'embed', loadChildren: () => import('./embed/embed.module').then((m) => m.EmbedModule) },
  ]),
  ShellHosted.childRoutes([
    { path: 'hosted', loadChildren: () => import('./hosted/hosted.module').then((m) => m.HostedModule) },
  ]),
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
