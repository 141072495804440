import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaymentTechWhatIf } from '@app/interfaces/payment-tech.interface';
import { PolicyData } from '@app/interfaces/policy.interface';
import { BillingStore } from '@app/interfaces/billing-store.interface';
// import { constant } from 'buffer';
import { constants } from '../constants';
import { PolicyJSON } from '@app/interfaces/policy-response.interface';
import { PolicyResponseToPolicy } from '@app/interfaces/policy-response-to-policy.mapping';
import { CanopyPolicyResponseToPolicy } from '@app/interfaces/canopy-policy-response-to-policy.mapping';
import { AptFeatureFlagService } from '@archinsurance-da/arch-portal-tools';
import { PaymentScheduleMock } from './payment-schedule.mock';

// import * as objectMapper from 'object-mapper';
const PRODUCTS = ['canopy', 'essentialpl'];
const product_mapping = {
  ea: 'canopy',
  pl: 'essentialpl',
};

@Injectable({
  providedIn: 'root',
})
export class BillingInfoService {
  dummyPaymentSchedule: boolean = false;

  constructor(private http: HttpClient, private ffService: AptFeatureFlagService) {
    this.ffService.flagChange.subscribe((flags) => {
      // console.log('environment.environmentName', environment.environmentName);
      if (environment.environmentName != 'PROD') {
        this.dummyPaymentSchedule = this.ffService.getFlag('dummyPaymentSchedule');
      }
    });
  }

  getPolicy(policyId: string, product: string = 'essentialpl', consumer: boolean = false) {
    let apiEndPointURL = environment.billingPortalConsumerApiHost;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (!consumer) {
      apiEndPointURL = environment.billingPortalApiHost;
      headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    }
    headers = headers.append('Accept', 'application/json');

    if (!PRODUCTS.includes(product) && product_mapping.hasOwnProperty(product)) {
      product = product_mapping[product];
    }
    headers = headers.append('product', product);

    return this.http
      .get<any>(
        apiEndPointURL + `${constants[consumer ? 'getPolicyConsumerEndPoint' : 'getPolicyEndPoint']}/${policyId}`,
        { headers }
      )
      .pipe(
        map((policy: PolicyJSON) => {
          let policyData;
          if (product == 'essentialpl') {
            policyData = new PolicyResponseToPolicy().map(policy);
          } else if (product == 'canopy') {
            policyData = new CanopyPolicyResponseToPolicy().map(policy);
          }
          return policyData;
        })
      );
  }

  /*

  */
  getPolicyOld(policyId: string) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    headers = headers.append('Accept', 'application/json');

    const payload = {
      policyContext: {
        policy: {
          actionCode: 'getPolicy',
          productTypeCode: 'PW',
          policyId: policyId,
          transactionId: '1',
          versionId: '1',
          optionNo: '1',
        },
      },
    };

    return this.http
      .post<any>(environment.billingPortalApiHost + constants.getPolicyEndPoint, payload, { headers })
      .pipe(
        map((policy: PolicyData) => {
          return policy;
        })
      );
  }

  private plTemplate: PaymentTechWhatIf = {
    WhatIfTransactionSchedule: {
      RequestHeader: {
        SourceSystemRequestNo: '7851251523103544',
      },
      Transaction: {
        BillType: 'DIRECT', //WHOLESALE
        BrokerNo: '00089',
        InvoicingCurrency: 'USD',
        LineOfBusiness: 'EA',
        OperatingCompany: 'AIC',
        PolicyEffectiveDate: '',
        PolicyExpirationDate: '',
        PolicyNo: 'POLACNTS',
        PreviousSourceTransactionRequestNo: '123',
        ProductCode: '5010',
        ScheduleAsOfDate: '',
        State: 'AL',
        TransactionCurrency: 'USD',
        TransactionDetail: {
          PaymentPlan: '25DP9', //'10PAY',//25DP9
          ReceivableDetails: {
            CommissionPercent: '20.00', //20
            ReceivableCode: 'PREMIUM',
            TransactionCurrencyCommissionAmount: '', //200
            TransactionCurrencyReceivableAmount: '', //2000
          },
        },
        TransactionEffectiveDate: '',
        UnderwritingCompany: 'AIC',
        WhatIfTransaction: 'WHAT_IF_NEW',
      },
    },
  };

  getPaymentWhatIfPayLoad(
    total: number,
    effDate: string,
    expDate: string,
    paymentPlanCode: string = '25DP9'
  ): PaymentTechWhatIf {
    let newPl = JSON.parse(JSON.stringify(this.plTemplate));

    const commissionPercent: number = parseInt(
      newPl.WhatIfTransactionSchedule.Transaction.TransactionDetail.ReceivableDetails.CommissionPercent
    );
    newPl.WhatIfTransactionSchedule.Transaction.TransactionDetail.ReceivableDetails.TransactionCurrencyCommissionAmount =
      (total * (commissionPercent / 100)).toFixed(2).toString();
    newPl.WhatIfTransactionSchedule.Transaction.TransactionDetail.ReceivableDetails.TransactionCurrencyReceivableAmount =
      total.toFixed(2).toString();
    newPl.WhatIfTransactionSchedule.Transaction.PolicyEffectiveDate = effDate;
    newPl.WhatIfTransactionSchedule.Transaction.PolicyExpirationDate = expDate;
    newPl.WhatIfTransactionSchedule.Transaction.ScheduleAsOfDate = effDate;
    newPl.WhatIfTransactionSchedule.Transaction.TransactionEffectiveDate = effDate;
    newPl.WhatIfTransactionSchedule.Transaction.TransactionDetail.PaymentPlan = paymentPlanCode;

    return newPl;
  }

  private dummySchedule: any = PaymentScheduleMock.data;

  getPaymentSchedule(
    total: number,
    effDate: string,
    expDate: string,
    paymentPlanCode?: string,
    consumer: boolean = false
  ): Observable<any> {
    if (this.dummyPaymentSchedule) {
      console.info(
        '%c !! Showing mock payment schedule. Dates and amounts may not be correct.',
        'color: #0071f2; font-weight: bold'
      );
      return of(this.dummySchedule);
    } else {
      // console.log('total', total);
      const payload: PaymentTechWhatIf = this.getPaymentWhatIfPayLoad(total, effDate, expDate, paymentPlanCode);
      //https://archconnect-stage.archinsurance.com/default/documentation/billing
      let apiEndPointURL = environment.billingPortalConsumerApiHost;
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      if (!consumer) {
        apiEndPointURL = environment.billingPortalApiHost;
        headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
      }
      headers = headers.append('Accept', 'application/json');

      //https://archconnect-stage.archinsurance.com/billing/payment/schedule

      return this.http
        .post<any>(
          apiEndPointURL + constants[consumer ? 'paymentScheduleConsumerEndpoint' : 'paymentScheduleEndpoint'],
          payload,
          { headers }
        )
        .pipe(
          map((scheduleResult) => {
            return scheduleResult;
            // return this.mapMajescoScheduleToPaymentSchedule(scheduleResult);
          })
        );
    }
  }

  bindPolicy(policyStore: BillingStore): Observable<any> {
    const payload = {
      quoteNumber: policyStore.policyData.policy.policyId,
      paymentPlan: policyStore.paymentProposal,
    };
    //https://archconnect-stage.archinsurance.com/default/documentation/billing

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    headers = headers.append('Accept', 'application/json');

    //https://archconnect-stage.archinsurance.com/billing/payment/schedule
    return this.http.post<any>(environment.billingPortalApiHost + constants.bindEndpoint, payload, { headers }).pipe(
      map((result) => {
        return result;
        // return this.mapMajescoScheduleToPaymentSchedule(scheduleResult);
      })
    );
  }

  downloadQuote(policyStore: BillingStore): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    // headers = headers.append('lob', '');
    // headers = headers.append('product', 'bop');
    headers = headers.append('Accept', 'application/pdf');

    policyStore.policyData.policy.actionCode = 'generateQuote';

    let product = 'essentialpl';
    if (
      policyStore.policyData.referenceData.product.productOffering &&
      policyStore.policyData.referenceData.product.productOffering == 'MSPPL'
    ) {
      product = 'essentialpl';
    } else {
      product = 'canopy';
    }

    const payload = {
      product: product,
      policyId: policyStore.policyData.policy.policyId,
    };

    return this.http
      .post(environment.billingPortalApiHost + constants.printQuoteEndpoint, payload, {
        headers,
        responseType: 'blob',
      })
      .pipe(
        map((uidResult) => {
          return uidResult;
          // return this.mapMajescoScheduleToPaymentSchedule(scheduleResult);
        })
      );
  }

  updatePolicy(policyData: PolicyData, consumer: boolean = false): Observable<any> {
    let _policy: PolicyData = JSON.parse(JSON.stringify(policyData)); //copy object so the below settings are not stored

    _policy.policy.actionCode = 'updatePolicy';
    _policy.policy.aipUserRoleType = '2';
    _policy.policy.productTypeCode = 'PW';
    _policy.policy.transactionId = '1';
    _policy.policy.ctInt = '1';
    _policy.policy.id = '1';
    delete _policy.responseJSON;
    delete _policy.policy.TotTaxFeesAndSurcharge;
    delete _policy.policy.taxFeesSurchargeList;

    const payload = {
      policyContext: _policy,
    };
    //https://archconnect-stage.archinsurance.com/default/documentation/billing
    let apiEndPointURL = environment.billingPortalConsumerApiHost;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (!consumer) {
      apiEndPointURL = environment.billingPortalApiHost;
      headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    }
    headers = headers.append('Accept', 'application/json');

    //https://archconnect-stage.archinsurance.com/billing/payment/schedule
    return this.http
      .post<any>(
        apiEndPointURL + constants[consumer ? 'updatePolicyConsumerEndpoint' : 'updatePolicyEndpoint'],
        payload,
        { headers }
      )
      .pipe(
        map((result) => {
          return result;
          // return this.mapMajescoScheduleToPaymentSchedule(scheduleResult);
        })
      );
  }

  getLimitandRetention(
    agentCd: any,
    product: string = 'PL',
    productOffering: string = 'MSPPL',
    consumer: boolean = false
  ) {
    let apiEndPointURL = environment.billingPortalConsumerApiHost;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (!consumer) {
      apiEndPointURL = environment.billingPortalApiHost;
      headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    }
    headers = headers.append('Accept', 'application/json');

    // if (!PRODUCTS.includes(product) && product_mapping.hasOwnProperty(product)) {
    //   product = product_mapping[product];
    // }
    // headers = headers.append('product', product);

    return this.http
      .post<any>(
        apiEndPointURL +
          constants[consumer ? 'limitsAndRetentionConsumerEndpoint' : 'limitsAndRetentionEndpoint'] +
          `?lob=${product}&product=${productOffering}&agentCd=${agentCd}`,
        {},
        { headers }
      )
      .pipe(
        map((result) => {
          return result;
          // return this.mapMajescoScheduleToPaymentSchedule(scheduleResult);
        })
      );
  }
}
