import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/i18n';
import { PolicyComponent } from './pages/policy/policy.component';
import { SuccessComponent } from './pages/success/success.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { AccessDeniedComponent } from '@app/@shared/acccess-denied/access-denied.component';
import { MaintenanceComponent } from '@app/@shared/maintenance/maintenance.component';
import { ConfirmComponent } from './pages/confirm/confirm.component';

const routes: Routes = [
  { path: 'policy/:product/:id', component: PolicyComponent, data: { title: extract('Home') }, pathMatch: 'full' },
  { path: 'policy/:id', component: PolicyComponent, data: { title: extract('Home') }, pathMatch: 'full' },
  { path: 'policy', redirectTo: 'policy/unknown', pathMatch: 'full' },
  { path: '', redirectTo: 'policy/unknown', pathMatch: 'full' },
  { path: 'home/:id', redirectTo: 'policy/:id', pathMatch: 'full' },
  { path: 'confirm', component: ConfirmComponent, data: { title: extract('Confirm') }, pathMatch: 'full' },
  { path: 'payment', component: PaymentComponent, data: { title: extract('Payer Info') }, pathMatch: 'full' },
  { path: 'success', component: SuccessComponent, data: { title: extract('Success') }, pathMatch: 'full' },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: 'maintenance', component: MaintenanceComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class DirectRoutingModule {}
