<div fxLayout="column">
  <ng-container *ngIf="policyStore?.policyData?.policy.quoteStatus === 'ISS'">
    <apt-state-block state="success" title="Success"></apt-state-block>

    <div fxLayout="row">
      <div fxFlex fxFlexOffset.gt-sm="30" fxFlex.gt-sm="40" style="text-align: justify">
        <p>
          The payment has been successfully processed. We will email the policy to you with a copy to your agent. You
          will also receive a payment confirmation email and how to access your policy information shortly.
        </p>
        <p><strong>Thank you.</strong></p>
      </div>
    </div>

    <!-- <apt-button-footer>
      <ng-container buttonFooterRight>
        <dtc-button primary (click)="downloadPolicy()">
          {{ downloading ? 'Downloading Policy' : 'Download Policy' }}</dtc-button
        >
      </ng-container>
    </apt-button-footer> -->
  </ng-container>

  <ng-container *ngIf="policyVerified && policyStore?.policyData?.policy.quoteStatus !== 'ISS'">
    <apt-state-block state="success" title="Success"></apt-state-block>

    <div fxLayout="row">
      <div fxFlex fxFlexOffset.gt-sm="30" fxFlex.gt-sm="40" style="text-align: justify">
        <p>
          The request has been received and is being processed. We will email the policy to you with a copy to your
          agent. You will also receive a payment confirmation email and how to access your policy information shortly.
        </p>
        <p><strong>Thank you.</strong></p>
      </div>
    </div>
  </ng-container>
</div>
<!-- TODO: when problem -->
