import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BillingInfoService } from '@shared/services/billing-info.service';
import { BillingStoreService } from '@app/home/store/billing-store.service';
import { BillingStore } from '@app/interfaces/billing-store.interface';
import { PaymentSummaryStoreService } from './payment-summary-store.service';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.scss'],
})
export class PaymentSummaryComponent implements OnChanges {
  @Input() editable: boolean = false;
  @Input() prompt: boolean = false;
  @Input() refreshInstallments: boolean = false;
  @Input() policyStore: BillingStore;
  @Input() product: string;
  @Input() consumer: boolean = false;

  loadingSchedules: number = 0;
  schedulesRequested: string[] = [];

  errorLoadingSchedules: boolean = false;

  installments: any[] = [];

  paymentPlans: any;

  constructor(
    private billingStoreService: BillingStoreService,
    private billingInfoService: BillingInfoService,
    private paymentSummaryStoreService: PaymentSummaryStoreService,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.refreshInstallments) {
      this.paymentSummaryStoreService.init(true); //reset when starting fresh
    }
    this.paymentSummaryStoreService.paymentPlans$.subscribe((plans: any) => {
      this.paymentPlans = plans;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.paymentPlans) {
      this.paymentPlans = this.paymentSummaryStoreService.get();
    }

    if (changes.policyStore.currentValue != changes.policyStore.previousValue) {
      // console.log('changes.policyStore', changes.policyStore);
      this.updatePaymentProposal();

      //LOAD/RELOAD PAYMENT SCHEDULE
      this.getPaymentSchedule();
    }
  }

  getPaymentSchedule() {
    for (let x in this.paymentPlans[this.product]) {
      let plan = this.paymentPlans[this.product][x];

      if (!plan.installments.length || this.refreshInstallments) {
        // console.log('this.policyStore.total', this.policyStore.total);

        if (
          this.policyStore.total > 0 &&
          this.policyStore.effectiveDate &&
          this.policyStore.expirationDate &&
          !this.schedulesRequested.includes(plan.code)
        ) {
          // console.log('plan.code', plan.code);
          // console.log('schedulesRequested', this.schedulesRequested.concat(', '));

          this.loadingSchedules++;
          this.schedulesRequested.push(plan.code);

          const fees = this.policyStore.feesAmount;
          let whatIfTotal = this.policyStore.total;
          if (fees) {
            whatIfTotal = whatIfTotal - fees;
          }

          this.billingInfoService
            .getPaymentSchedule(
              whatIfTotal,
              this.policyStore.effectiveDate,
              this.policyStore.expirationDate,
              plan.code,
              this.consumer
            )
            .pipe(debounceTime(1000))
            .subscribe(
              (data) => {
                // console.log('data', data);
                let installments = [];

                if (
                  data?.responseTransactionSchedule?.RequestResponse?.SuccessFlag === 'SUCCESS' &&
                  data?.responseTransactionSchedule?.TransactionResponse?.TransactionDetail[0]
                    ?.InstallmentScheduleSummarybyDueDate
                ) {
                  // console.log('data.responseTransactionSchedule.TransactionResponse.TransactionDetail', data.responseTransactionSchedule.TransactionResponse.TransactionDetail[0]?.InstallmentScheduleSummarybyDueDate);

                  let paymentSummary =
                    data.responseTransactionSchedule.TransactionResponse.TransactionDetail[0]
                      ?.InstallmentScheduleSummarybyDueDate;

                  for (let i = 0; i < paymentSummary.length; i++) {
                    const payment = paymentSummary[i];
                    // console.log('payment', payment);
                    for (let n = 0; n < payment.TransactionReceivableSummary.length; n++) {
                      const installment = payment.TransactionReceivableSummary[n];
                      const _BillDueDate = payment.BillDueDate;
                      let billDate = _BillDueDate.split('-');
                      const time = billDate.pop(); //remove the last item which is a time (causes date parsing to fail)
                      installment.BillDueDate = billDate.join('-') + 'T' + time;

                      // console.log('installment', installment);
                      // console.log('installment.BillDueDate', installment.BillDueDate);

                      installment.BillDueDateObj = new Date(installment.BillDueDate);
                      installment.amount = installment.Balance; // + installment.CommissionAmount; //have to total the commission and balance to get correct payment amount
                      installments.push(installment);
                    }
                  }

                  //Sort the array by sequence
                  installments.sort((a, b) => {
                    const aNo = parseInt(a.InstallmentNo);
                    const bNo = parseInt(b.InstallmentNo);
                    return aNo - bNo;
                  });

                  this.setInstallments(plan, installments);
                } else {
                  console.log('this.errorLoadingSchedules 1', this.errorLoadingSchedules);
                  this.errorLoadingSchedules = true;
                  console.error('Error Loading Payment Schedule');
                  data?.responseTransactionSchedule?.TransactionResponse?.TransactionDetail[0]?.Messages.forEach(
                    (_message: any) => {
                      console.error('Code:', _message.Code, '. Description:', _message.Description);
                    }
                  );

                  // console.warn(
                  //   'response missing responseTransactionSchedule.TransactionResponse.TransactionDetail[0]?.InstallmentScheduleSummarybyDueDate'
                  // );
                }

                this.loadingSchedules--;
              },
              (error: any) => {
                const currentRoute = this.router.url;
                if (currentRoute.includes('/policy/')) {
                  console.log('this.errorLoadingSchedules 2', this.errorLoadingSchedules);
                  this.errorLoadingSchedules = true;
                }
                this.loadingSchedules = 0;
                console.error(error);
              }
            );
        }
      } else {
        if (this.policyStore.paymentProposal != '1PAY') {
          this.updatePaymentProposal();
        }
      }
    }
  }

  setInstallments(plan: any, installments: any = []) {
    // console.log('installments', installments);

    this.errorLoadingSchedules = false;

    plan.installments = installments;

    this.paymentSummaryStoreService.set(this.paymentPlans);

    if (this.policyStore.paymentProposal != '1PAY') {
      this.updatePaymentProposal();
    }
  }

  updatePaymentProposal() {
    this.policyStore.premiumAmount = 0;
    this.policyStore.dueToday = 0;
    this.policyStore.feesAmount = 0;
    this.policyStore.total = 0;

    // console.log('this.product', this.product);
    // console.log('this.policyStore.paymentProposal', this.policyStore.paymentProposal);
    // console.log('this.paymentPlans[this.product][this.policyStore.paymentProposal]', this.paymentPlans[this.product][this.policyStore.paymentProposal]);

    this.installments =
      this.paymentPlans && this.paymentPlans[this.product]
        ? this.paymentPlans[this.product][this.policyStore.paymentProposal]?.installments
        : [];

    if (this.policyStore?.policyData?.policy) {
      if (this.policyStore.paymentProposal != '1PAY' && this.installments?.length) {
        this.policyStore.premiumAmount = this.installments[0].amount; //due today is the amount in the first installment of the payment schedule
      } else {
        this.policyStore.premiumAmount = this.policyStore?.policyData?.policy?.policyITDPremium
          ? parseFloat(this.policyStore.policyData.policy.policyITDPremium)
          : 0;
      }

      this.policyStore.dueToday = this.policyStore.premiumAmount;

      if (this.policyStore.policyData?.policy?.TotTaxFeesAndSurcharge) {
        this.policyStore.feesAmount = parseFloat(this.policyStore.policyData.policy.TotTaxFeesAndSurcharge);
      } else if (this.policyStore?.policyData?.policy?.taxFeesSurchargeList) {
        //add the fees to this.policyStore.dueToday
        for (let i = 0; i < this.policyStore.policyData.policy.taxFeesSurchargeList.length; i++) {
          const fee = this.policyStore.policyData.policy.taxFeesSurchargeList[i];
          if (fee.hasOwnProperty('premiumAmt')) {
            this.policyStore.feesAmount += parseFloat(fee.premiumAmt);
          }
        }
      }

      this.policyStore.dueToday += this.policyStore.feesAmount;

      this.policyStore.total = this.policyStore?.policyData?.policy?.policyITDPremium
        ? parseFloat(this.policyStore.policyData.policy.policyITDPremium) + this.policyStore.feesAmount
        : 0;

      // this.policyStore.policyData.policy.Payment_Plan_Cd = this.policyStore.paymentProposal;

      this.policyStore.policyData.responseJSON.policy.Payment_Plan_Cd = this.policyStore.paymentProposal;

      this.billingStoreService.setBillingStore(this.policyStore);
    }

    // this.bindForm.reset();//repercussions?
  }
}
