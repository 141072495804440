import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { constants } from '@app/@shared/constants';
import { MaintenanceSheduleService } from '@app/@shared/services/maintenance-schedule.service';
import { MaintenanceSchedule, MaintenanceService } from '@archinsurance-da/arch-portal-tools';
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  constructor(
    private maintenanceService: MaintenanceService,
    private router: Router,
    private maintSchedService: MaintenanceSheduleService
  ) {}

  ngOnInit() {
    this.maintSchedService.maintSchedChange.subscribe((schedules: MaintenanceSchedule[]) => {
      //Change startDTS and endDTS to show currentMain
      const currentMaintenances = this.maintenanceService.isScheduledMaintenance(schedules, true); //IF second param is false means do not show upcoming

      // console.log('schedules', schedules);
      // console.log('currentMaintenances', currentMaintenances);

      const currentMaint = currentMaintenances[0];
      if (currentMaint) {
        this.router.navigate(['maintenance'], { skipLocationChange: true });
      }
    });
  }
}
