import { InterfaceMap, InterfaceMapper } from './interface-mapper';

export class PolicyResponseToPolicy extends InterfaceMapper {
  constructor() {
    super();
  }

  taxFeesTransformer(feesTaxesArr: any) {
    let newTaxesFees: any[] = [];

    feesTaxesArr.forEach((data: any) => {
      newTaxesFees.push({
        itemType: data?.TFS_Type,
        tfsTransactionAmt: data.TFS_Transaction_Amt,
        otherDesc: data.TFS_Description,
        premiumAmt: data.TFS_ITD_Amt,
        rate: data.TFS_Rate,
        riskState: data.TFS_State_Cd,
        overRideInd: data.TFS_Override_Ind,
        tfsCode: data.TFS_Code,
      });
    });

    return newTaxesFees;
  }

  issuingCompanyTransformer(data: any) {
    return data == '1' || data == 1 ? 'Arch Insurance Company' : data;
  }

  mappings: InterfaceMap[] = [
    {
      leftPath: '__ROOT__',
      rightPath: 'responseJSON',
    },
    {
      leftPath: 'policy.quoteNbr',
      rightPath: 'policy.policyId',
    },
    {
      leftPath: 'policy.guid',
      rightPath: 'policy.guid',
    },
    {
      leftPath: 'policy.agentCd',
      rightPath: 'policy.agentCd',
    },
    {
      leftPath: 'policy.productOffering',
      rightPath: 'referenceData.product.productOffering',
    },
    {
      leftPath: 'policy.namedInsured',
      rightPath: 'policy.addressDto.insuredName',
    },
    {
      leftPath: 'policy.policyEffDt',
      rightPath: 'policy.policyEffectiveDate',
    },
    {
      leftPath: 'policy.policyExpDt',
      rightPath: 'policy.policyExpirationDate',
    },
    {
      leftPath: 'policy.retroactiveDate',
      rightPath: 'policy.retroactiveDate',
    },
    {
      leftPath: 'policy.policyLimit',
      rightPath: 'policy.policyLimit',
    },
    {
      leftPath: 'policy.LimitAggregate',
      rightPath: 'policy.limitAggregate',
    },
    {
      leftPath: 'policy.PolicyRetention',
      rightPath: 'policy.policyAggregateRetention',
    },
    {
      leftPath: 'policy.AIP_STATUS',
      rightPath: 'policy.quoteStatus',
    },
    {
      leftPath: 'policy.writingCompany',
      rightPath: 'policy.issuingCompany',
      transformer: this.issuingCompanyTransformer,
    },
    {
      leftPath: 'policy.Insured_Contact_Person',
      rightPath: 'policy.insuredContactPerson',
    },
    {
      leftPath: 'policy.Insured_Email_Txt',
      rightPath: 'policy.insuredEmailTxt',
    },
    {
      leftPath: 'policy.Insured_Phone_No',
      rightPath: 'policy.insuredPhoneNo',
    },
    {
      leftPath: 'policy.Insured_Address_Street_Line_1_Txt',
      rightPath: 'policy.addressDto.insuredAddressStreetLine1Txt',
    },
    {
      leftPath: 'policy.Insured_Address_Street_Line_2_Txt',
      rightPath: 'policy.addressDto.insuredAddressStreetLine2Txt',
    },
    {
      leftPath: 'policy.Insured_Address_City_Txt',
      rightPath: 'policy.addressDto.insuredAddressCityTxt',
    },
    {
      leftPath: 'policy.Insured_Address_Postal_Cd',
      rightPath: 'policy.addressDto.insuredAddressPostalCd',
    },
    {
      leftPath: 'policy.Insured_Address_State_Cd',
      rightPath: 'policy.addressDto.insuredAddressStateCd',
    },
    {
      leftPath: 'policy.polNbr',
      rightPath: 'policy.polNbr',
    },
    {
      leftPath: 'policy.policyAPRPPremium',
      rightPath: 'policy.policyITDPremium',
    },
    {
      leftPath: 'policy.TotTaxFeesAndSurcharge',
      rightPath: 'policy.TotTaxFeesAndSurcharge',
    },
    {
      leftPath: 'policy.Insured_Class_of_Business_Name',
      rightPath: 'referenceData.classOfBusiness.insuredClassofBusinessName',
    },
    {
      leftPath: 'policy.Insured_Class_of_Business_Desc',
      rightPath: 'referenceData.classOfBusiness.insuredClassofBusinessDesc',
    },
    {
      leftPath: 'policy.underwriterEmailAddress',
      rightPath: 'referenceData.branch.agencyUnderwriterEmail',
    },
    {
      leftPath: 'policy.taxFeeAndSurcharges',
      rightPath: 'policy.taxFeesSurchargeList',
      transformer: this.taxFeesTransformer,
    },
    // {
    //     leftPath: 'policy.XXXXXXXX',
    //     rightPath: 'policy.XXXXXXXXXXXX'
    // },
  ];
}
