/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import { hmrBootstrap } from './hmr';
import { AppConfig, RuntimeConfig } from '@app/app.config';

fetch('/assets/config.json').then(async res => {
  const runtimeConfig = (await res.json()) as RuntimeConfig;
  const appConfig = AppConfig.load(runtimeConfig);

  if (environment.production) {
    enableProdMode();
  }

  const bootstrap = () =>
    platformBrowserDynamic([{ provide: AppConfig, useValue: appConfig }]).bootstrapModule(AppModule);

  if (environment.hmr) {
    hmrBootstrap(module, bootstrap);
  } else {
    bootstrap().catch(err => console.error(err));
  }
});
