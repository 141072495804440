import { DefaultDirectTheme } from '../default.theme';

export class HostedTheme extends DefaultDirectTheme {
  header: string = 'Contact an Agent';
  name: string = 'hosted';

  phone: '(888) 910-6553';
  phoneD2C: '(888) 910-6553';

  constructor() {
    super();
  }
}
