import { AuthConfig, OAuthModuleConfig } from 'angular-oauth2-oidc';

export interface RuntimeConfig {
  apiUrl: string;
  environment?: string;
  auth: {
    audience: string;
    issuer: string;
    clientId: string;
  };
}

export class AppConfig {
  serverUrl: string;
  defaultLanguage: string;
  supportedLanguages: string[];
  authConfig: AuthConfig;
  authModuleConfig: OAuthModuleConfig;

  static load(runtimeConfig: RuntimeConfig): AppConfig {
    return {
      serverUrl: runtimeConfig.apiUrl,
      defaultLanguage: 'en-US',
      supportedLanguages: ['en-US'],
      authConfig: {
        issuer: runtimeConfig.auth.issuer,
        redirectUri: window.location.origin,
        clientId: runtimeConfig.auth.clientId,
        customQueryParams: {
          audience: runtimeConfig.auth.audience,
        },
        scope: 'openid profile email',
        responseType: 'code',
        silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
        useSilentRefresh: true,
        timeoutFactor: 0.75,
        clearHashAfterLogin: false,
        logoutUrl:
          runtimeConfig.auth.issuer +
          'v2/logout?client_id=' +
          runtimeConfig.auth.clientId +
          '&returnTo=' +
          encodeURIComponent(window.location.origin),
      } as AuthConfig,
      authModuleConfig: {
        resourceServer: {
          allowedUrls: [runtimeConfig.apiUrl],
          sendAccessToken: true,
        },
      } as OAuthModuleConfig,
    };
  }
}
