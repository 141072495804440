import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BillingStore } from '@app/interfaces/billing-store.interface';
import { PolicyData } from '@app/interfaces/policy.interface';
import { BillingInfoService } from '@shared/services/billing-info.service';
import { PayerInfoService } from '../payer-info/payer-info.service';
import { BillingStoreService } from '../store/billing-store.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {
  downloading: boolean = false;
  policyVerified: boolean = false;

  constructor(
    private router: Router,
    private payerInfoService: PayerInfoService,
    private billingStoreService: BillingStoreService,
    private billingInfoService: BillingInfoService
  ) {}

  successPayData: any;
  policyStore: BillingStore = {};

  ngOnInit(): void {
    this.successPayData = JSON.parse(sessionStorage.getItem('successPayData'));
    this.policyStore = this.billingStoreService.getBillingStore();

    // console.log('this.policyStore', this.policyStore);

    if (this.policyStore.policyData.policy.quoteStatus !== 'ISS') {
      //if policy is not issued, try updating the policy before throwing them out
      this.billingInfoService
        .getPolicy(this.policyStore.policyData.policy.policyId, this.policyStore.product)
        .subscribe((policyData: PolicyData) => {
          this.policyStore.policyData = policyData;
          // console.info(`%c Policy Number ${this.policyStore.policyData.policy.polNbr}`, 'font-weight: bold; color: #0057b8');
          this.billingStoreService.setBillingStore(this.policyStore);
          this.policyVerified = true;
        });
    } else {
      this.policyVerified = true;
    }

    // console.log('this.successPayData', this.successPayData);
  }

  goToPolicy() {
    this.router.navigate(['policy', this.policyStore.policyData.policy.policyId]);
  }

  downloadPolicy() {
    if (!this.downloading) {
      this.downloading = true;
      this.payerInfoService.downloadPolicy(this.policyStore).subscribe((resultBytes) => {
        var blob = new Blob([resultBytes], { type: 'application/pdf' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `arch-policy-${this.policyStore.policyData.policy.policyId}.pdf`;
        link.click();

        this.downloading = false;
      });
    }
  }
}
