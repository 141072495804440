import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  AptButtonModule,
  AptFormModule,
  AptInformModule,
  AptLayoutModule,
  AptLoadersModule,
  AptMaintenanceModule,
  AptTableModule,
  AptTemplatesModule,
  AptTypographyModule,
} from '@archinsurance-da/arch-portal-tools';

import { LoaderComponent } from './loader/loader.component';
import { AccessDeniedComponent } from './acccess-denied/access-denied.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { PaymentSummaryComponent } from './components/payment-summary/payment-summary.component';
import { ContactBoxComponent } from './components/contact-box/contact-box.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AptTemplatesModule,
    AptButtonModule,
    TranslateModule,
    AptLayoutModule,
    AptTypographyModule,
    AptFormModule,
    AptInformModule,
    AptLoadersModule,
    AptTableModule,
    AptMaintenanceModule,
  ],
  declarations: [
    LoaderComponent,
    AccessDeniedComponent,
    MaintenanceComponent,
    PaymentSummaryComponent,
    ContactBoxComponent,
  ],
  exports: [LoaderComponent, AccessDeniedComponent, MaintenanceComponent, PaymentSummaryComponent, ContactBoxComponent],
})
export class SharedModule {}
