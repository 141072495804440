import { DefaultDirectTheme } from './themes/default.theme';
import { NationwideTheme } from './themes/nationwide/nationwide.theme';
import { ProgressiveTheme } from './themes/progressive/progressive.theme';
import { AppalachianTheme } from './themes/appalachian/appalachian.theme';
export const constants = {
  bookOfBusinessEndPoint: 'aipdashboardms/getYtdMtdCurrentBookOfBusiness',
  currentBoBForFromToDateEndPoint: 'aipdashboardms/getCurrentBoBForFromToDate',
  actionItemsEndPoint: 'aipdashboardms/getActionItems',
  permissionEndPoint: 'aipauth0api/getRolesAndPortals',
  paymentScheduleEndpoint: 'billing-portal-api/paymentSchedule',
  paymentScheduleConsumerEndpoint: 'billing-portal-api/consumer/paymentSchedule',
  initializePaymentEndPoint: 'billing-portal-api/initializePayment',
  initializePaymentConsumerEndPoint: 'billing-portal-api/consumer/initializePayment',
  paymentQueryEndpoint: 'billing-portal-api/paymentQuery',
  postPaymentEndpoint: 'billing-portal-api/postPayment',
  postPaymentConsumerEndpoint: 'billing-portal-api/consumer/postPayment',
  getPolicyEndPoint: 'billing-portal-api/getPolicy',
  getPolicyConsumerEndPoint: 'billing-portal-api/consumer/getPolicy',
  updatePolicyEndpoint: 'billing-portal-api/updatePolicy',
  updatePolicyConsumerEndpoint: 'billing-portal-api/consumer/updatePolicy',
  printPolicyEndpoint: 'billing-portal-api/issueletter',
  printQuoteEndpoint: 'billing-portal-api/quoteletter',
  bindEndpoint: 'billing-portal-api/bind',
  limitsAndRetentionEndpoint: 'billing-portal-api/limitsAndRetentions',
  limitsAndRetentionConsumerEndpoint: 'billing-portal-api/consumer/limitsAndRetentions',
  renewalEndpoint: 'billing-portal-api/consumer/quote/renewal',
  policyStatusEndpoint: 'billing-portal-api/consumer/policy/status/',

  getRolesAgencies: '/aip-admin-api/getRolesAgenciesAndProducts',
  getPortals: '/aip-admin-api/getPortals/',
  topmenuEndPoint: 'aip-authorization-ms/authorize/menuset/menu',
  getSignature: '/aip-admin-api/getUserSignature',
  updateSignature: 'aip-admin-api/saveUserSignature',
  serviceEndPointaddUser: 'aip-admin-api/addUser',
  serviceEndPointUserDetail: '/aip-base-api/aip-base-api/getUserdetails/',
  bopQuoteEndPoint: 'api/quote',
  producerIds: {
    P43274: {
      id: 'P43274',
      name: 'Nationwide',
      theme: new NationwideTheme(),
      phone: '(844) 731-7067',
      phoneD2C: '(877) 718-1590',
    },
    P43580: {
      id: 'P43580',
      name: 'Canopy',
      theme: new DefaultDirectTheme(),
      phone: '(888) 910-6553',
      phoneD2C: '(888) 910-6553',
    },
    P18089: {
      id: 'P18089',
      name: 'Appalachian',
      theme: new AppalachianTheme(),
      phone: '',
      phoneD2C: '',
    },
    P44681: {
      id: 'P44681',
      name: 'Progressive',
      theme: new ProgressiveTheme(),
      phone: '(888) 302-8533',
      phoneD2C: '(877) 232-6408',
    },
  },
  contactForCanopy: '(888) 910-6553',
  contactForNationwide: '(844) 731-7067',
  contactForNationwideD2C: '(877) 718-1590',
  contactForProgressive: '(888) 302-8533',
  contactForProgressiveD2C: '(877) 232-6408',
  maintenanceStartDate: '01/27/2023 19:00:00 GMT-0400 (Eastern Daylight Time)',
  maintenanceEndDate: '01/28/2023 22:00:00 GMT-0400 (Eastern Daylight Time)',
  warnMinutesBefore: 5760,
  chase: {
    version2: {
      iframePath: '/hpf/1_1/?uID=',
      jsPath: '/hpf/js/hpfParent.min.js',
    },
    version3: {
      iframePath: '/hpf/?uid=',
      jsPath: '/includes/hpfParent.min.js',
    },
  },
};
