import { DefaultDirectTheme } from '../default.theme';
import { constants } from './../../../@shared/constants';
export class NationwideTheme extends DefaultDirectTheme {
  header: string = 'Contact an Agent';
  name: string = 'nationwide';
  logo: string = 'nwide_logo.png';
  logoSquare: string = 'nwide_logo_square.png';

  phone: '(844) 731-7067';
  phoneD2C: '(877) 718-1590';

  agent: any = {
    header: 'Agent Information',
    name: '',
    phone: '(844) 731-7067',
    phoneD2C: '(877) 718-1590',
    email: '',
  };
  // primaryColor: string = '#0057b8';//inherited from default

  constructor() {
    super();
  }
}
