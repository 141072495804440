import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { TextWaiterPipe } from '@app/pipes/text-waiter/text-waiter.pipe';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import {
  AptButtonModule,
  AptFormModule,
  AptLayoutModule,
  AptTypographyModule,
  AptTemplatesModule,
  AptDialogModule,
  AptIframeModule,
  AptTableModule,
  AptLoadersModule,
  AptTextLabelModule,
  AptTooltipModule,
  AptInformModule,
} from '@archinsurance-da/arch-portal-tools';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SuccessComponent } from './success/success.component';
import { PayerInfoComponent } from './payer-info/payer-info.component';
import { QuoteHeaderComponent } from './components/quote-header/quote-header.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AptButtonModule,
    AptFormModule,
    AptLayoutModule,
    AptLoadersModule,
    AptTextLabelModule,
    AptTypographyModule,
    AptTemplatesModule,
    AptDialogModule,
    AptIframeModule,
    AptTableModule,
    AptInformModule,
    AptTooltipModule,
    HomeRoutingModule,
  ],
  declarations: [TextWaiterPipe, HomeComponent, SuccessComponent, PayerInfoComponent, QuoteHeaderComponent],
})
export class HomeModule {}
