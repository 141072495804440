import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss'],
})
export class AccessDeniedComponent implements OnInit {
  @Input() isAccessDenied = false;
  @Input() messageDenied: string | undefined;
  @Input() stateInfo: string = 'Info';
  constructor() {}

  ngOnInit() {}
}
