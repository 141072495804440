<apt-h2>Payment Summary</apt-h2>

<ng-container *ngIf="errorLoadingSchedules">
  <apt-inform state="error"
    >There was a problem loading your payment schedule options. Please select "Pay in Full" or try again.</apt-inform
  >
</ng-container>

<ng-container *ngIf="policyStore">
  <div [ngClass]="{ 'prompt-anim': prompt }">
    <p>
      <apt-radio label="Pay in Full">
        <input
          id="policyStore.paymentProposalF"
          type="radio"
          name="paymentProposal"
          value="1PAY"
          [(ngModel)]="policyStore.paymentProposal"
          (change)="updatePaymentProposal()"
          [disabled]="!editable || policyStore?.policyData?.policy.quoteStatus !== 'QTD'"
        />
      </apt-radio>
    </p>
    <!-- <ng-container *ngIf="!loadingSchedules && !errorLoadingSchedules"> -->
    <ng-container *ngIf="!loadingSchedules && paymentPlans[product]">
      <p *ngFor="let plan of paymentPlans[product] | keyvalue">
        <apt-radio
          label="Pay in {{
            plan.value.code != '25DP9 ' && plan.value.installments.length > 0
              ? plan.value.installments.length + ' '
              : ''
          }}Installments"
        >
          <input
            id="policyStore.paymentProposal{{ plan.key }}"
            type="radio"
            name="paymentProposal"
            [value]="plan.value.code"
            [(ngModel)]="policyStore.paymentProposal"
            (change)="updatePaymentProposal()"
            [disabled]="!editable || policyStore?.policyData?.policy.quoteStatus !== 'QTD' || loadingSchedules"
          />
        </apt-radio>
      </p>
    </ng-container>
    <ng-container *ngIf="loadingSchedules > 0 && !errorLoadingSchedules">
      <p>
        <span>Loading payment schedule options</span>
        <apt-text-waiter [text]="null" fallbackText="" [fallbackTime]="30000"></apt-text-waiter>
      </p>
    </ng-container>
  </div>

  <apt-table [columnsFlex]="['70', '']" [columnsAlign]="['', 'right']">
    <apt-tr rowType="header">
      <apt-tc>Schedule</apt-tc>
      <apt-tc>Amounts</apt-tc>
    </apt-tr>

    <apt-tr>
      <apt-tc>{{
        policyStore.paymentProposal === ''
          ? 'Premium'
          : policyStore.paymentProposal == '1PAY'
          ? 'Premium'
          : 'First Installment'
      }}</apt-tc>
      <apt-tc>
        <apt-text-waiter [text]="policyStore.premiumAmount | currency"></apt-text-waiter>
      </apt-tc>
    </apt-tr>
    <ng-container
      *ngIf="
        policyStore?.policyData?.policy?.TotTaxFeesAndSurcharge &&
        policyStore?.policyData?.policy?.TotTaxFeesAndSurcharge > 0
      "
    >
      <apt-tr>
        <apt-tc>Taxes/Surcharge</apt-tc>
        <apt-tc>{{ policyStore.policyData.policy.TotTaxFeesAndSurcharge | currency }}</apt-tc>
      </apt-tr>
    </ng-container>

    <ng-container *ngIf="policyStore?.policyData?.policy?.taxFeesSurchargeList">
      <apt-tr *ngFor="let fee of policyStore.policyData.policy.taxFeesSurchargeList">
        <apt-tc>{{ fee.itemType }}</apt-tc>
        <apt-tc>{{ fee.premiumAmt | currency }}</apt-tc>
      </apt-tr>
    </ng-container>

    <ng-container *ngIf="policyStore.paymentProposal != '1PAY'">
      <apt-tr rowType="gray">
        <apt-tc>Due Today</apt-tc>
        <apt-tc>{{ policyStore.dueToday | currency }}</apt-tc>
      </apt-tr>

      <ng-container *ngFor="let installment of installments; let i = index">
        <apt-tr *ngIf="i != 0">
          <apt-tc fxFlex="80">{{ installment.BillDueDateObj | date: 'MM/dd/yyyy' }} </apt-tc>
          <apt-tc fxFlex fxLayout="column" fxAlignItems="center end">{{ installment.amount | currency }} </apt-tc>
        </apt-tr>
      </ng-container>
    </ng-container>

    <apt-tr rowType="footer">
      <apt-tc>{{ policyStore.paymentProposal == '1PAY' ? 'Total' : 'Total Payments' }}</apt-tc>
      <apt-tc>{{ policyStore.total | currency }}</apt-tc>
    </apt-tr>
  </apt-table>
</ng-container>
