import { InterfaceMap, InterfaceMapper } from './interface-mapper';
import { PolicyResponseToPolicy } from './policy-response-to-policy.mapping';

export class CanopyPolicyResponseToPolicy extends PolicyResponseToPolicy {
  statusTransformer(quoteCode: any) {
    const quoteCodeMapping = {
      IP: 'IPG',
      Q: 'QTD',
      B: 'BND',
      I: 'ISS',
    };
    return quoteCodeMapping[quoteCode];
  }

  constructor() {
    super();

    this.overrideLeftPath('policy.policyAPRPPremium', 'policy.policyITDPremium');
    this.overrideLeftPath('policy.Insured_Class_of_Business_Name', 'policy.NAICS_Desc');
    this.overrideLeftPath('policy.AIP_STATUS', 'policy.Policy_Status_Cd', this.statusTransformer);
  }
}
