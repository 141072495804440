<div *ngIf="TESTINGCHASE" fxLayout="row" fxLayoutGap="16px" style="padding-bottom: 20px">
  <div fxFlex="30%">
    <apt-input label="Chase UID: DO NOT USE IN PROD" style="width: 100%">
      <input type="text" placeholder="Chase UID" [(ngModel)]="chaseUID" />
    </apt-input>
  </div>
  <div fxFlex fxLayout="column" fxLayoutAlign="end start">
    <apt-button tertiary (click)="updateChaseIframe(chaseUID)">Load UID in Iframe</apt-button>
  </div>
</div>

<ng-container
  *ngIf="BYPASSBINDCALL || policyStore?.policyData?.policy.quoteStatus === 'BND' || chaseUID || TESTINGCHASE"
>
  <!-- <app-quote-header
    [insuredName]="policyStore?.policyData?.policy.addressDto.insuredName"
    [address]="addressString"
    [policyType]="coverageLiteralName"
    [issuingCompany]="policyStore?.policyData?.policy.issuingCompany"
  >
  </app-quote-header> -->

  <dtc-page-content>
    <ng-container main>
      <dtc-bread-crumbs></dtc-bread-crumbs>

      <apt-h1 class="underline-title">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div fxFlex class="theme-title" [style.color]="theme?.primaryColor">Payment</div>
        </div>
      </apt-h1>

      <ng-container *ngIf="iframeSrc">
        <apt-h2>Payment Information</apt-h2>

        <apt-iframe
          [src]="iframeSrc"
          class="payment-iframe"
          ngClass.lt-md="mobile-payment"
          [sandbox]="true"
        ></apt-iframe>
      </ng-container>
    </ng-container>

    <ng-container right>
      <payment-summary [editable]="false" [policyStore]="policyStore" [product]="policyStore.product">
      </payment-summary>

      <div *ngIf="show_chaseFormOnly" style="margin-top: 20px">
        <apt-checkbox label="No Post Payment Processing">
          <input type="checkbox" name="chaseFormOnly" required [(ngModel)]="chaseFormOnly" />
        </apt-checkbox>
      </div>

      <div *ngIf="show_chaseFormOnly && displayUID" style="margin-top: 10px">Chase UID: {{ displayUID }}</div>

      <ng-container *ngIf="errors && errors.length">
        <div style="margin-top: 16px">
          <apt-h2 underline>Errors</apt-h2>
        </div>
        <!-- <apt-inform state="error">{{errors.join(',')}}</apt-inform> -->
        <apt-inform *ngFor="let error of errors" state="error">{{ error.message }}</apt-inform>
      </ng-container>

      <contact-box [overrideTheme]="d2cTheme" [phoneType]="'phoneD2C'"></contact-box>
    </ng-container>
  </dtc-page-content>

  <apt-dialog title="" modal="true" [(open)]="processingPayment" width="670px" [showCloseX]="false">
    <ng-container dialogBody>
      <apt-waiter
        [evaluate]="!processingPayment"
        [title]="'Processing Payment'"
        [rollingText]="processPayMessages"
        [rollingWait]="4000"
        [repeat]="false"
        [timeout]="0"
        [(timedOut)]="processPayTimedOut"
        (timedOutChange)="processPayTimeOutChange($event)"
        [contactInfo]="contactInfo"
      ></apt-waiter>
    </ng-container>
  </apt-dialog>
</ng-container>

<ng-container *ngIf="!BYPASSBINDCALL && policyStore?.policyData?.policy.quoteStatus === 'QTD'">
  <apt-template-nested-state state="info" title="POLICY NOT BOUND">
    <ng-container message>
      <p>This policy must be in "BIND" status before you can pay.</p>
      <apt-button [primary]="true" (click)="goToPolicy()">Go to Policy Page</apt-button>
    </ng-container>
  </apt-template-nested-state>
</ng-container>

<ng-container
  *ngIf="policyStore?.policyData?.policy.quoteStatus !== 'QTD' && policyStore?.policyData?.policy.quoteStatus !== 'BND'"
>
  <apt-template-nested-state state="info" title="INVALID POLICY">
    <ng-container message>
      <p>This policy cannot proceed to payment. It has either been issued or is not valid.</p>
    </ng-container>
  </apt-template-nested-state>
</ng-container>
