<apt-content-box>
  <div fxLayout="row">
    <div fxFlex fxLayout="column" fxLayoutAlign="start start">
      <apt-text-label label="Insured Name">
        <apt-text-waiter [text]="insuredName"></apt-text-waiter>
      </apt-text-label>
    </div>

    <div fxFlex fxLayout="column" fxLayoutAlign="start start">
      <apt-text-label label="Address">
        <apt-text-waiter [text]="address"></apt-text-waiter>
      </apt-text-label>
    </div>

    <div fxFlex fxLayout="column" fxLayoutAlign="start start">
      <apt-text-label label="Product/Policy Type">
        <apt-text-waiter [text]="policyType"> </apt-text-waiter>
      </apt-text-label>
    </div>

    <div fxFlex fxLayout="column" fxLayoutAlign="start start">
      <apt-text-label label="Insurance Company">
        <apt-text-waiter [text]="issuingCompany"></apt-text-waiter>
      </apt-text-label>
    </div>
  </div>
</apt-content-box>
