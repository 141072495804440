import { Injectable } from '@angular/core';
import { PaymentPlans } from '@app/interfaces/policy.interface';
import { BehaviorSubject } from 'rxjs';

export interface PaymentPlan {
  code: string;
  installments: any[];
}

@Injectable({
  providedIn: 'root',
})
export class PaymentSummaryStoreService {
  public paymentPlans: any;

  public paymentPlans$ = new BehaviorSubject<any>({});

  private useLocalStorage: boolean = true;

  constructor() {
    this.init();
  }

  private readonly initPlans: PaymentPlans = {
    essentialpl: {
      '25DP9': {
        code: '25DP9',
        installments: [],
      },
    },
    ea: {
      '2PAY60': {
        code: '2PAY60',
        installments: [],
      },
      '4PAY40': {
        code: '4PAY40',
        installments: [],
      },
    },
  };

  init(reset: boolean = false) {
    let fetchedStorage = localStorage.getItem('payment-summary');

    let storagePlans = !reset && this.useLocalStorage && fetchedStorage ? JSON.parse(fetchedStorage) : undefined;

    this.set(storagePlans || this.initPlans);
  }

  public set(paymentPlans: any): void {
    this.paymentPlans = paymentPlans;
    this.paymentPlans$.next(this.paymentPlans);
    if (this.useLocalStorage) {
      localStorage.setItem('payment-summary', JSON.stringify(this.paymentPlans));
    }
  }

  get() {
    return this.paymentPlans;
  }
}
