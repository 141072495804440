<ng-container *ngIf="policyStore?.policyData?.policy.quoteStatus === 'ISS'">
  <apt-template-nested-state state="success" title="Payment Success" style="margin-top: -20px">
    <ng-container message>
      <div fxFlexOffset="30" fxFlex="40" style="text-align: justify">
        <p>
          The payment has been successfully processed. You may download the policy by clicking the
          <strong>Download Policy</strong> button below. We will email the policy to the Insured with a copy to you
          shortly. The Insured will also receive confirmation of payment and how to access their policy information
          shortly.
        </p>
        <p><strong>Thank you.</strong></p>
      </div>
    </ng-container>

    <apt-button-footer footer>
      <ng-container buttonFooterRight>
        <apt-button [primary]="true" (click)="downloadPolicy()" [loading]="downloading" [loadingExpectedTime]="9000">{{
          downloading ? 'Downloading Policy' : 'Download Policy'
        }}</apt-button>
      </ng-container>
    </apt-button-footer>
  </apt-template-nested-state>
</ng-container>

<ng-container *ngIf="policyVerified && policyStore?.policyData?.policy.quoteStatus !== 'ISS'">
  <apt-template-nested-state state="success" title="Success">
    <ng-container message>
      <div fxFlexOffset="30" fxFlex="40" style="text-align: justify">
        <p>
          This request has been received and is being processed. We will email the policy to the Insured with a copy to
          you shortly. The Insured will also receive confirmation of payment and how to access their policy information.
        </p>
        <p><strong>Thank you.</strong></p>
      </div>
      <p></p>
      <!-- <apt-button [primary]="true" (click)="goToPolicy()">Go to Policy Page</apt-button> -->
    </ng-container>
  </apt-template-nested-state>
</ng-container>

<!-- TODO: when problem -->
