<button
  #button
  [attr.disabled]="disabled ? disabled : null"
  type="{{ type }}"
  [ngClass]="{ primary: primary, secondary: secondary, 'btn-large': large }"
  (mousedown)="doRipple($event)"
>
  <fa-icon *ngIf="icon" [icon]="icon"></fa-icon>
  <div class="button-text"><ng-content></ng-content></div>
</button>
