import { DefaultDirectTheme } from '../default.theme';
// import { constants } from '../../constants';
import { environment } from '@env/environment';
export class ProgressiveTheme extends DefaultDirectTheme {
  header: string = 'Contact an Agent';
  name: string = 'progressive';
  logo: string = 'logo-progressive.png';
  logoSquare: string = '';

  redirectUrl: string = environment.pgrRedirectUrl;

  agent: any = {
    header: 'Agent Information',
    name: '',
    phone: '(888) 302-8533',
    phoneD2C: '(877) 232-6408',
    hours: 'Monday – Friday, 8:00AM - 9:30PM EST',
    email: 'businessinsurance@email.progressive.com',
  };
  // primaryColor: string = '#2D95E5'; //inherited from default
  primaryColor: string = '#0075BF'; //inherited from default

  constructor() {
    super();
  }
}
