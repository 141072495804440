import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CanopyTheme } from '../themes/canopy/canopy.theme';
import { DefaultDirectTheme, Theme } from '../themes/default.theme';
import { GenericTheme } from '../themes/generic/generic.theme';
import { HostedTheme } from '../themes/hosted/hosted.theme';
import { NationwideTheme } from '../themes/nationwide/nationwide.theme';
import { ProgressiveTheme } from '../themes/progressive/progressive.theme';
import { AppalachianTheme } from '../themes/appalachian/appalachian.theme';

@Injectable({
  providedIn: 'root',
})
export class ThemingService {
  constructor() {}

  theme: Theme;

  private readonly _theme = new BehaviorSubject<Theme>(new DefaultDirectTheme());
  readonly themeChange = this._theme.asObservable();

  setTheme(theme: any) {
    // console.log('theme from arg', theme);
    if (theme === 'nationwide') {
      this.theme = new NationwideTheme();
    } else if (theme === 'progressive') {
      this.theme = new ProgressiveTheme();
    } else if (theme === 'appalachian') {
      this.theme = new AppalachianTheme();
    } else if (theme === 'canopy') {
      this.theme = new CanopyTheme();
    } else if (theme === 'hosted') {
      this.theme = new HostedTheme();
    } else if (theme && typeof theme !== 'string') {
      this.theme = theme;
    } else {
      this.theme = new GenericTheme();
    }
    // console.log('this.theme theme service', this.theme);
    this._theme.next(this.theme);
  }
}
