import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/i18n';
import { HomeComponent } from './home.component';
import { SuccessComponent } from './success/success.component';
import { Shell } from '@app/shell/shell.service';
import { PayerInfoComponent } from './payer-info/payer-info.component';
import { AccessDeniedComponent } from '@app/@shared/acccess-denied/access-denied.component';
import { MaintenanceComponent } from '@app/@shared/maintenance/maintenance.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'policy/:product/:id', component: HomeComponent, data: { title: extract('Home') }, pathMatch: 'full' },
    { path: 'policy/:id', component: HomeComponent, data: { title: extract('Home') }, pathMatch: 'full' },
    { path: 'policy', redirectTo: 'policy/unknown', pathMatch: 'full' },
    { path: 'home', redirectTo: 'policy/unknown', pathMatch: 'full' },
    //{ path: '', redirectTo: 'policy/unknown', pathMatch: 'full' },
    { path: 'home/:id', redirectTo: 'policy/:id', pathMatch: 'full' },
    { path: 'payer-info', component: PayerInfoComponent, data: { title: extract('Payer Info') }, pathMatch: 'full' },
    { path: 'success', component: SuccessComponent, data: { title: extract('Success') }, pathMatch: 'full' },
    { path: 'access-denied', component: AccessDeniedComponent },
    { path: 'maintenance', component: MaintenanceComponent },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class HomeRoutingModule {}
