<ng-container *ngIf="!renewalId || renewalId == 'na'">
  <apt-template-nested-state state="info" title="Invalid Policy">
    <ng-container message>
      <p>
        <ng-container *ngIf="renewalId == 'na'">
          <p>No policy id was provided. To access the payment portal, please follow a valid link.</p>
        </ng-container>
      </p>
    </ng-container>
  </apt-template-nested-state>
</ng-container>

<apt-h1 class="renewal-title">Renewal Questions</apt-h1>

<form [formGroup]="renewalForm" (ngSubmit)="submitRenewalForm()" aptForm>
  <div class="question-wr">
    <apt-text-label
      >Has there been a change in your primary business operations since you purchased the policy from Arch last
      year?</apt-text-label
    >

    <div class="radio-wr">
      <apt-radio label="Yes">
        <input id="yesRadioQ1" type="radio" value="Y" formControlName="q1_radio" name="q1_radio" />
      </apt-radio>

      <apt-radio label="No">
        <input id="noRadioQ1" type="radio" value="N" formControlName="q1_radio" name="q1_radio" />
      </apt-radio>
    </div>
  </div>

  <div class="question-wr">
    <apt-text-label
      >Do you have any unreported claims since you purchased the policy from Arch last year?</apt-text-label
    >

    <div class="radio-wr">
      <apt-radio label="Yes">
        <input id="yesRadioQ2" type="radio" value="Y" formControlName="q2_radio" name="q2_radio" />
      </apt-radio>

      <apt-radio label="No">
        <input id="noRadioQ2" type="radio" value="N" formControlName="q2_radio" name="q2_radio" />
      </apt-radio>
    </div>
  </div>

  <div class="question-wr">
    <apt-text-label
      >Has your revenue increased or decreased by over 20% since you purchased the policy from Arch last
      year?</apt-text-label
    >

    <div class="radio-wr">
      <apt-radio label="Yes">
        <input id="yesRadioQ3" type="radio" value="Y" formControlName="q3_radio" name="q3_radio" />
      </apt-radio>

      <apt-radio label="No">
        <input id="noRadioQ3" type="radio" value="N" formControlName="q3_radio" name="q3_radio" />
      </apt-radio>
    </div>

    <div class="revenue-block" *ngIf="renewalForm.value.q3_radio == 'Y'">
      <apt-input label="Updated Revenue" [aptInput]="revenueInput">
        <input #revenueInput type="text" placeholder="Updated Revenue" name="revenue" formControlName="revenue" />
      </apt-input>
    </div>
  </div>

  <apt-button-footer>
    <ng-container buttonFooterRight>
      <dtc-button primary type="submit" [disabled]="submitting || !renewalForm.valid">
        {{ submitting ? 'Submitting...' : 'Submit' }}
      </dtc-button>
    </ng-container>
  </apt-button-footer>
</form>

<apt-dialog title="" modal="true" [(open)]="submitting" width="670px" [showCloseX]="false">
  <ng-container dialogBody>
    <apt-waiter
      [evaluate]="renewalFinished"
      [title]="'Processing Renewal'"
      [rollingText]="renewalMessages"
      [rollingWait]="5000"
      [repeat]="true"
      [timeout]="0"
      [error]="renewalError"
    ></apt-waiter>
    <!-- [contactInfo]="renewalContactInfo" -->
  </ng-container>
</apt-dialog>

<apt-dialog [title]="postRenewalTitle" modal="true" [(open)]="postRenewalRequest" [showCloseX]="true">
  <ng-container dialogBody>
    <p>{{ postRenewalMessage }}</p>
  </ng-container>
  <!-- <ng-container dialogFooter>
    <apt-button-footer>
      <ng-container buttonFooterLeft>
        <apt-button secondary="default" (click)="bindDialog = false">Cancel</apt-button>
      </ng-container>

      <ng-container buttonFooterRight>
        <apt-button
          [primary]="true"
          type="submit"
          [disabled]="!bindForm.valid || doingBind"
          [title]="bindForm.valid ? '' : 'Please agree to the required options.'"
        >
          <ng-container *ngIf="doingBind">Please wait <apt-text-waiter></apt-text-waiter> </ng-container>
          <ng-container *ngIf="BYPASSBINDCALL && !doingBind">Confirm and Proceed</ng-container>
          <ng-container *ngIf="!BYPASSBINDCALL && !doingBind">Bind and proceed to payment</ng-container>
        </apt-button>
      </ng-container>
    </apt-button-footer>
  </ng-container> -->
</apt-dialog>

<apt-dialog [title]="renewalStatusTitle" modal="true" [(open)]="showRenewalStatusDialog" [showCloseX]="false">
  <ng-container dialogBody>
    <p>{{ renewalStatusMessage }}</p>
  </ng-container>
</apt-dialog>
