<div class="contact-box">
  <div style="margin-top: 16px">
    <apt-h2>{{ theme.header }}</apt-h2>
  </div>

  <div [ngClass]="{ centerText: theme.logoSquare, leftText: !theme.logoSquare }">
    <img *ngIf="theme.logoSquare" src="../../../assets/direct/{{ theme.logoSquare }}" alt="{{ theme.name }}" />
    <div class="contact-box-items">
      <div *ngIf="theme.agent && theme.agent.header">{{ theme.agent.header }}</div>
      <div *ngIf="theme.agent && theme.agent.name">{{ theme.agent.name }}</div>

      <div *ngIf="phone">{{ phone }}</div>

      <div *ngIf="theme.agent && theme.agent.hours">
        {{ theme.agent.hours }}
      </div>

      <div *ngIf="theme.agent && theme.agent.email" [ngClass]="{ 'email-sm': theme.agent.email.length > 35 }">
        <a href="mailto:{{ theme.agent.email }}" target="_blank">{{ theme.agent.email }}</a>
      </div>
    </div>
  </div>
</div>
