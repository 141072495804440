import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { I18nModule } from '@app/i18n';
import { ShellHostedComponent } from './shell.component';
import { AptNavigationModule, AptButtonModule, AptTemplatesModule } from '@archinsurance-da/arch-portal-tools';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    I18nModule,
    FlexLayoutModule,
    AptButtonModule,
    AptNavigationModule,
    AptTemplatesModule,
    RouterModule,
  ],
  declarations: [ShellHostedComponent],
})
export class ShellHostedModule {}
