import configVars from '../assets/config.json';

import { environment as env_local } from './environment.local';
import { environment as env_dev } from './environment.dev';
import { environment as env_int } from './environment.int';
import { environment as env_stage } from './environment.stage';
import { environment as env_prod } from './environment.prod';

const _environments = {
  local: env_local,
  dev: env_dev,
  int: env_int,
  stage: env_stage,
  prod: env_prod,
};

function detectEnv() {
  if (!configVars?.environment || (configVars?.environment == 'local' && !location.origin.includes('localhost'))) {
    //detect origin and choose appropriate environment
    console.log('determining environment from origin');
    for (let x in _environments) {
      if (_environments[x]?.origins.includes(location.origin)) {
        return x;
      }
    }
  } else {
    return configVars.environment;
  }
}

const detectedEnv = detectEnv();

console.log('detectedEnv', detectedEnv);

export const environment = _environments[detectedEnv || 'local'];
