import { Component, Input, OnInit } from '@angular/core';
import { ThemingService } from '@shared/services/theming.service';
import { Theme } from '@shared/themes/default.theme';

@Component({
  selector: 'contact-box',
  templateUrl: './contact-box.component.html',
  styleUrls: ['./contact-box.component.scss'],
})
export class ContactBoxComponent implements OnInit {
  @Input() overrideTheme: Theme;

  @Input() phoneType: string = 'phone';

  theme: Theme;
  phone: string;

  constructor(private themeService: ThemingService) {
    this.themeService.themeChange.subscribe((theme: Theme) => {
      this.theme = theme;
      this.setPhone();
    });
  }

  ngOnInit(): void {
    // this.theme = this.themeService.theme;

    if (this.overrideTheme) {
      this.theme = this.overrideTheme; //optionally override theme for just one instance
    }

    this.setPhone();
  }

  setPhone() {
    if (this.phoneType && this.theme && this.theme.agent[this.phoneType]) {
      // console.log('setting from setPhone', this.theme);
      this.phone = this.theme.agent[this.phoneType];
    } else {
      this.phone = this.theme?.agent?.phone;
    }
  }
}
