import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ThemingService } from '@shared/services/theming.service';
import { Theme } from '@shared/themes/default.theme';
import { ActivatedRoute, Router } from '@angular/router';
import { MaintenanceSchedule, MaintenanceService } from '@archinsurance-da/arch-portal-tools';
import { constants } from '@app/@shared/constants';
import { MaintenanceSheduleService } from '@app/@shared/services/maintenance-schedule.service';
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShellComponent implements OnInit {
  theme: Theme;

  constructor(
    private themeService: ThemingService,
    private maintenanceService: MaintenanceService,
    private router: Router,
    private maintSchedService: MaintenanceSheduleService
  ) {
    this.themeService.themeChange.subscribe((theme: Theme) => {
      this.theme = theme;
      // console.log('set shell theme');
      // console.log('theme', theme);
    });
  }

  ngOnInit() {
    this.themeService.setTheme('generic'); //detect and set this from shell for now.

    this.maintSchedService.maintSchedChange.subscribe((schedules: MaintenanceSchedule[]) => {
      //Change startDTS and endDTS to show currentMain
      const currentMaintenances = this.maintenanceService.isScheduledMaintenance(schedules, true); //IF second param is false means do not show upcoming

      // console.log('schedules', schedules);
      // console.log('currentMaintenances', currentMaintenances);

      const currentMaint = currentMaintenances[0];
      if (currentMaint) {
        this.router.navigate(['maintenance'], { skipLocationChange: true });
      }
    });
  }
}
