import { DefaultDirectTheme } from '../default.theme';

export class GenericTheme extends DefaultDirectTheme {
  header: string = 'Support/Contact Information';
  name: string = 'generic';

  primaryColor: string = '#0057b8';

  agent: any = {
    header: '',
    name: 'Arch Customer Support',
    phone: '888-910-6553',
    phoneD2C: '(888) 910-6553',
    email: 'customerservice@archinsurance.com',
  };

  constructor() {
    super();
  }
}
