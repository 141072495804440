import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { I18nModule } from '@app/i18n';
import { ShellComponent } from './shell.component';
import {
  AptNavigationModule,
  AptButtonModule,
  AptTemplatesModule,
  AptMaintenanceModule,
} from '@archinsurance-da/arch-portal-tools';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    I18nModule,
    FlexLayoutModule,
    AptButtonModule,
    AptNavigationModule,
    AptTemplatesModule,
    RouterModule,
    AptMaintenanceModule,
  ],
  declarations: [ShellComponent],
})
export class ShellDirectModule {}
