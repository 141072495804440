<apt-header [env]="envName">
  <apt-portal-switcher portalSwitcher [rolesAndPortals]="rolesAndPortals"></apt-portal-switcher>
</apt-header>

<apt-top-nav
  [userProfile]="userProfile"
  [showRoleSwitcher]="false"
  [settingsItems]="settingsItems"
  [logoutAction]="doLogout()"
>
  <apt-menu [menuItems]="menuItems"></apt-menu>
</apt-top-nav>
