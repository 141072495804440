import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MaintenanceSchedule } from '@archinsurance-da/arch-portal-tools';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceSheduleService {
  constructor() {}

  private readonly _maintSched = new BehaviorSubject<MaintenanceSchedule[]>([
    {
      startDTS: '',
      endDTS: '',
    },
  ]);
  readonly maintSchedChange = this._maintSched.asObservable();

  setSchedule(schedule: MaintenanceSchedule[]) {
    this._maintSched.next(schedule);
  }
}
