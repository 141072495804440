<div class="shell" fxFill fxLayout="row" fxLayoutAlign="start stretch" [ngClass]="'theme-' + theme.name">
  <!-- sets wrapping theme class -->

  <div fxLayout="column" fxFlex fxFill fxLayoutAlign="start center">
    <div class="shell-header" fxFlex="nogrow" fxLayout="column" fxLayoutAlign="start center">
      <div fxLayout="row" fxFlex fxLayoutAlign="space-around center">
        <div *ngIf="theme.logo" fxFlex><img [src]="theme.logo" /></div>

        <div fxFlex fxLayout="row" fxLayoutAlign="end center">
          <img src="../../../assets/direct/archlogo-solid.png" alt="Arch Insurance" />
        </div>
      </div>
    </div>

    <div class="shell-body" fxFlex>
      <div class="shell-content">
        <router-outlet></router-outlet>
      </div>
    </div>

    <div class="shell-footer" fxFlex="nogrow" fxLayout="column" fxLayoutAlign="start center">
      <div fxLayout="column" fxFlex fxLayoutAlign="center stretch">
        <apt-footer></apt-footer>
      </div>
    </div>
  </div>
</div>
