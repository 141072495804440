import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { HomeModule } from './home/home.module';
import { ShellModule } from './shell/shell.module';
import { EmptyShellModule } from './empty-shell/empty-shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationModule } from '@app/authentication/authentication.module';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DirectModule } from './direct/direct.module';
import { ShellDirectModule } from './direct/shell/shell.module';
import { ShellHostedModule } from './hosted/shell/shell.module';
import { RenewalModule } from './renewal/renewal.module';
import { ShellRenewalModule } from './renewal/shell/shell.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    CoreModule,
    AuthenticationModule.forRoot(),
    TranslateModule.forRoot(),
    NgbModule,
    SharedModule,
    ShellModule,
    ShellHostedModule,
    ShellDirectModule,
    ShellRenewalModule,
    EmptyShellModule,
    HomeModule,
    DirectModule,
    RenewalModule,
    FlexLayoutModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
