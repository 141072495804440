import { Component, HostListener, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ChaseError } from '@app/interfaces/payment-tech.interface';
import { BillingInfoService } from '@shared/services/billing-info.service';
// import { IconDefinition, faCreditCard } from '@fortawesome/free-solid-svg-icons';
// import { Policy } from "@app/interfaces/policy.interface";
import { BillingStoreService } from '../store/billing-store.service';
import { PayerInfoService } from './payer-info.service';
import { BillingStore } from '@app/interfaces/billing-store.interface';
import { environment } from '../../../environments/environment';
import { constants } from './../../@shared/constants';
import { ThemingService } from '@shared/services/theming.service';
import { Theme } from '@shared/themes/default.theme';
import { AptFeatureFlagService } from '@archinsurance-da/arch-portal-tools';
import { PendoService } from '@app/@shared/services/pendo/pendo.service';
const DEFAULT_PRODUCT = 'essentialpl';

@Component({
  selector: 'app-payer-info',
  templateUrl: './payer-info.component.html',
  styleUrls: ['./payer-info.component.scss'],
})
export class PayerInfoComponent implements OnInit {
  // -- TEST VISA CARD: 4788250000028291
  TESTINGCHASE: boolean = false;
  chaseUID: string = '';
  // chaseUID: string = '4B9AD8DDFAB144ADAC4F0B5A48B94BA8';
  theme: Theme;
  BYPASSBINDCALL: boolean = true; //set to true for pay first bind after, false will require olicy to be bound before payment
  requestSent: boolean = false;
  iframeSrc: string = '';
  policyStore: BillingStore = {};
  addressString: string = '';
  errors: ChaseError[] = [];
  SIMULATE_POSTPAYMENT: boolean = false;
  processingPayment: boolean = false;
  coverageLiteralName: string;

  chase_version: number = 2;
  chaseSrc: string;

  show_chaseFormOnly: boolean = false; //environment.envName.toLowerCase() == 'dev';
  chaseFormOnly: boolean = false; //environment.envName.toLowerCase() == 'dev';
  displayUID: string;
  paramProduct: any;
  contactNumber: string;

  constructor(
    private router: Router,
    private payerInfoService: PayerInfoService,
    private billingStoreService: BillingStoreService,
    private route: ActivatedRoute,
    private themeService: ThemingService,
    private pendoService: PendoService,
    private ffService: AptFeatureFlagService
  ) {
    if (environment.chaseOrigin) {
      //chase sends alerts manually, override alerts from chase
      window.alert = function () {};
    }
  }

  producer: string = ''; //default nationwide
  setProducer(overrideProducerId?: string) {
    if (!/^P/.test(overrideProducerId) && overrideProducerId !== 'generic' && overrideProducerId !== 'auto') {
      overrideProducerId = `P${overrideProducerId}`;
    }

    // console.log('overrideProducerId', overrideProducerId);

    if (overrideProducerId && overrideProducerId != 'default') {
      this.producer = overrideProducerId;
    } else {
      this.producer = 'P43274'; //TODO: NEED TO GET FROM POLICY RESPONSE
    }

    if (overrideProducerId === 'generic') {
      this.themeService.setTheme('generic');
    } else {
      if (this.paramProduct == 'ea') {
        this.producer = 'P43580'; //Canopy
      }

      if (constants.producerIds.hasOwnProperty(this.producer) && constants.producerIds[this.producer].theme) {
        this.themeService.setTheme(constants.producerIds[this.producer].theme);
      } else if (!this.producer) {
        this.themeService.setTheme('generic');
      }
    }
  }

  private loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  private setChaseVersion(chase_version: number) {
    this.chase_version = chase_version;

    this.chaseSrc = `${environment.chaseOrigin[`version${this.chase_version}`].iframeOrigin}${
      constants.chase[`version${chase_version}`].iframePath
    }`;

    this.loadScript(
      `${environment.chaseOrigin[`version${this.chase_version}`].origin}${
        constants.chase[`version${this.chase_version}`].jsPath
      }`
    );
  }

  ngOnInit(): void {
    // this.themeService.setTheme('');
    this.themeService.themeChange.subscribe((theme: Theme) => {
      this.theme = theme;
      console.log('this.theme.name', this.theme.name);
    });

    this.ffService.flagChange.subscribe((flags: any) => {
      const enterCustomUID = !flags.enterCustomUID?.current ? flags.enterCustomUID : flags.enterCustomUID.current;
      // console.log('flags', flags);

      this.TESTINGCHASE = enterCustomUID;
    });

    this.policyStore = this.billingStoreService.getBillingStore();

    this.setProducer(this.policyStore.policyData.responseJSON.policy.agentCd);

    if (!this.policyStore) {
      this.router.navigate(['policy']);
    }
    // console.log('this.policyStore', this.policyStore);

    const product = this.policyStore.product;
    this.paramProduct = product == 'ea' ? product : DEFAULT_PRODUCT;

    if (this.paramProduct == 'ea') {
      this.theme.agent.phone = constants.contactForCanopy;
      this.themeService.setTheme(this.theme);
      this.setChaseVersion(2);
    } else {
      this.setChaseVersion(3);
    }

    this.addressString = `${this.policyStore.policyData.policy.addressDto.insuredAddressStreetLine1Txt}, ${this.policyStore.policyData.policy.addressDto.insuredAddressCityTxt}, ${this.policyStore.policyData.policy.addressDto.insuredAddressStateCd} ${this.policyStore.policyData.policy.addressDto.insuredAddressPostalCd}`;

    if (this.policyStore?.policyData?.policy?.quoteCoverageList) {
      this.coverageLiteralName =
        this.policyStore?.policyData?.policy?.quoteCoverageList[0].coverageCodeInfo.coverageLiteralName.replace(
          'Miscellaneous/Specified',
          'Miscellaneous'
        );
    }

    if (this.policyStore?.policyData?.referenceData?.product?.productOffering) {
      const productMapping = {
        PRDCNP: 'Management Liability', //'Arch Corporate Canopy Policy Private Company Management Liability & Crime Insurance',
        MSPPL: 'Miscellaneous/Specified Professional Liability',
        MSSPL: 'Miscellaneous/Specified Professional Liability',
      };
      let productOffering = this.policyStore.policyData.referenceData.product.productOffering;
      // console.log('productOffering', productOffering);
      this.coverageLiteralName = productMapping[productOffering] ? productMapping[productOffering] : productOffering;
    }

    if (this.chaseUID) {
      //hosted_tokenize=store_authorize
      this.updateChaseIframe(this.chaseUID);
    } else {
      this.payerInfoService.getPaymentTechUID(this.policyStore).subscribe((data: any) => {
        // console.log('getPaymentTechUID data', data);
        //call iframe service
        // console.log('data', data);
        this.updateChaseIframe(data.uID);
      });
    }
  }

  updateChaseIframe(chaseUid: string) {
    console.log('chaseUid', chaseUid);
    this.displayUID = chaseUid;

    this.iframeSrc = `${this.chaseSrc}${chaseUid}`;

    console.log('this.iframeSrc', this.iframeSrc);
  }

  goToPolicy() {
    this.router.navigate(['policy', this.policyStore.policyData.policy.policyId]);
  }

  processPayMessages: string[] = [
    'Thank you for your request.',
    'Validating your payment.',
    'Issuing the policy.',
    'Updating and synchronizing our records.',
    'Saving updated information to your policy.',
    'Polishing up final details.',
    'Your time is very important to us.',
    'We should be finishing up shortly. Thank you for your patience.',
  ];
  processPayTimedOut: boolean = false;
  processPayTimeOutChange($event: any) {
    console.log('payment timed out $event', $event);
  }

  //Handle events coming from the payment iframe
  @HostListener('window:message', ['$event'])
  handlePostMessages($event: MessageEvent) {
    // this.SIMULATE_POSTPAYMENT = true;

    this.SIMULATE_POSTPAYMENT = this.chaseFormOnly;

    console.log('$event', $event);

    if ($event.origin === environment.chaseOrigin[`version${this.chase_version}`].origin) {
      this.errors = [];

      if ($event.data.subject == 'startPayment') {
        this.processingPayment = true;
      } else if ($event.data.subject == 'handlePaymentErrors') {
        this.processingPayment = false;
        console.log('handlePaymentErrors', $event.data.content); //$event.data.content.errorCode
        this.errors = this.payerInfoService.parseChaseErrors($event.data.content.errorCode);
        this.pendoService.track('handlePaymentErrors', this.policyStore, $event.data.content);
      } else if ($event.data.subject == 'hostedHandleDetailErrors') {
        this.processingPayment = false;
        console.log('hostedHandleDetailErrors', $event.data.content);
        // this.errors = this.payerInfoService.parseChaseErrors($event.data.content.errorCode);
      } else if ($event.data.subject == 'handleTransactionErrors') {
        this.processingPayment = false;
        this.errors = this.payerInfoService.parseChaseErrors($event.data.content.gateway_code);
        this.pendoService.track('handleTransactionErrors', this.policyStore, $event.data.content);
        console.log('hostedHandleDetailErrors', $event.data.content);
        // this.errors = this.payerInfoService.parseChaseErrors($event.data.content.errorCode);
      } else if ($event.data.subject == 'completeHostedPayment' || $event.data.subject == 'completePayment') {
        // console.log('completeHostedPayment', $event.data);

        //kick off post payment API calls
        if (!this.SIMULATE_POSTPAYMENT) {
          if (this.theme?.agent?.phone) {
            this.policyStore.policyData.responseJSON.policy.supportPhoneNo = this.theme.agent.phone;
          }
          const _UID = $event.data.content.uid || $event.data.content.uID;
          this.payerInfoService.doPostPaymentProcessing(_UID, this.policyStore).subscribe(
            (response: any) => {
              this.processingPayment = false;
              if (response === true || (response?.chaseSuccessCode === '000' && response?.returnCode === 'SUCCESS')) {
                sessionStorage.setItem('successPayData', JSON.stringify(response));

                if (
                  this.policyStore.redirectUrl &&
                  this.payerInfoService.validateUrl(atob(this.policyStore.redirectUrl))
                ) {
                  location.href = this.payerInfoService.getRedirectUrlWithParams(this.policyStore);
                } else {
                  this.router.navigate(['success']);
                }
              } else if (response.chaseErrorCode) {
                this.errors = this.payerInfoService.parseChaseErrors([response.chaseErrorCode]);
              } else if (response.returnMessage) {
                this.errors.push({
                  code: 'NA',
                  message: response.returnMessage.replace(/<br\s*[\/]?>/gi, ' '),
                });
              } else {
                this.errors.push({
                  code: 'NA',
                  message: 'There was an unexpected error. Please contact Arch Service Center for support.',
                });
              }
            },
            (errorResponse: any) => {
              this.errors.push({
                code: 'NA',
                message: 'There was an unexpected error. Please contact Arch Service Center for support.',
              });
            }
          );
        } else {
          setTimeout(() => {
            this.processingPayment = false;
            this.router.navigate(['success']);
          }, 1000);
        }
      } else if ($event.data.subject == 'cancelPayment') {
        this.pendoService.track('cancelledPaymentButton', this.policyStore);
        this.router.navigate([
          `policy${this.policyStore.product == 'ea' ? '/' + this.policyStore.product : ''}/${
            this.policyStore.policyData.policy.policyId
          }`,
        ]);
      } else {
        // this.pendoService.track('unknownError', this.policyStore);
        console.log('unhandled post message subject', $event.data.subject, $event.data);
      }
    }
  }
}
