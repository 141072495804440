<apt-template-page-three-row [decreasePaddingHeight]="true">
  <ng-container header>
    <app-header></app-header>
  </ng-container>

  <ng-container main>
    <apt-upcoming-maintenance></apt-upcoming-maintenance>
    <router-outlet></router-outlet>
  </ng-container>

  <ng-container footer>
    <apt-footer></apt-footer>
  </ng-container>
</apt-template-page-three-row>
