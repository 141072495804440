import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BillingStoreService } from '@app/home/store/billing-store.service';
import { MenuItem } from '@archinsurance-da/arch-portal-tools';

@Component({
  selector: 'dtc-bread-crumbs',
  templateUrl: './bread-crumbs.component.html',
  styleUrls: ['./bread-crumbs.component.scss'],
})
export class BreadCrumbsComponent implements OnInit {
  menuItems: MenuItem[] = [];

  constructor(private billingStoreService: BillingStoreService, public router: Router) {}

  ngOnInit(): void {
    this.billingStoreService.billingStores$.subscribe((policyStore) => {
      if (policyStore) {
        this.menuItems = [
          {
            text: 'Quote Details',
            routerLink: [`/direct/policy/${policyStore.product}/${btoa(policyStore.id)}`],
          },
          {
            text: 'Bind Confirmation',
            // disabled: true,
            routerLink: ['/direct/confirm'],
          },
          {
            text: 'Payment',
            routerLink: ['/direct/payment'],
          },
        ];
      }
    });
  }
}
