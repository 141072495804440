export interface InterfaceMap {
  leftPath: string;
  rightPath: string;
  transformer?: any;
}

export class InterfaceMapper {
  constructor() {}

  public mappings: InterfaceMap[];

  public overrideLeftPath(leftPath: string, newLeftPath: string, transformer?: any) {
    //for use when an implementing class needs to overide just some of the leftPath mappings
    let modifyMapping = this.mappings.find((mapping) => mapping.leftPath === leftPath);
    if (modifyMapping) {
      modifyMapping.leftPath = newLeftPath;
      if (transformer) {
        modifyMapping.transformer = transformer;
      }
    }
  }

  //left has the data (old object), leftPath mappings point to the location to find the data that needs to be mapped to the location specified by the right Path
  public map(left: any) {
    let returnObj = {};

    for (let i = 0; i < this.mappings.length; i++) {
      //loop the mappings
      const mapping = this.mappings[i];
      let leftValue = mapping.leftPath === '__ROOT__' ? left : this.getDeepValue(left, mapping.leftPath); //get the value at the location of the mapping

      const setValue = mapping.transformer && leftValue ? mapping.transformer(leftValue) : leftValue;
      this.constructPathOnObj(returnObj, mapping.rightPath, setValue); //construct the path to that location nd set the new value. This alters the returnObj directly
    }

    return returnObj;
  }

  private getDeepValue(obj: any, path: any) {
    for (var i = 0, path = path.split('.'), len = path.length; i < len; i++) {
      obj = obj[path[i]];
    }
    return obj;
  }

  private constructPathOnObj(obj: any, path: string, setValue?: any) {
    //this alters the obj directly
    let pathArr = path.split('.');
    for (let i = 0; i < pathArr.length; i++) {
      const pathItem = pathArr[i];
      const isLast = i == pathArr.length - 1;
      if (!obj.hasOwnProperty(pathItem) || isLast) {
        obj[pathItem] = isLast ? setValue : {};
      }
      obj = obj[pathItem]; //set to current path so next interation has the next nested location correctly set.
    }
  }
}
