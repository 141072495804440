<ng-container *ngIf="policyStore?.id">
  <dtc-page-content>
    <ng-container main>
      <dtc-bread-crumbs></dtc-bread-crumbs>

      <apt-h1 class="underline-title">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div fxFlex class="theme-title" [style.color]="theme?.primaryColor">Bind Confirmation</div>
        </div>
      </apt-h1>

      <form [formGroup]="bindForm" (ngSubmit)="goToPayment()">
        <p>Before proceeding, please review and select the required fields below:</p>

        <div>
          <apt-checkbox
            label="You affirm and certify that all the information in this application herein is complete, true and correct to the best of your knowledge and belief."
          >
            <input type="checkbox" name="affirm" formControlName="affirm" required />
          </apt-checkbox>
        </div>

        <div style="margin-top: 8px">
          <apt-checkbox
            label="You understand and agree that a third party payment processor (the “Payment Processor”) processes the payments for this Policy.  Our current Payment Processor is Chase Paymentech, and your payments are processed by Chase. Arch Insurance Group Inc. does not control and is not liable for the security or performance of the Payment Processor."
          >
            <input type="checkbox" name="legal" formControlName="legal" required />
          </apt-checkbox>
        </div>

        <div style="margin-top: 8px">
          <apt-checkbox
            label="When you provide payment information, you represent and warrant that the information is accurate and that you are authorized to use the payment method provided."
          >
            <input type="checkbox" name="legal2" formControlName="legal2" required />
          </apt-checkbox>
        </div>

        <div style="margin-top: 8px">
          <apt-checkbox label="You agree to sign up for autopay.">
            <input type="checkbox" name="autopay" formControlName="autopay" required />
          </apt-checkbox>
        </div>

        <apt-button-footer>
          <ng-container buttonFooterLeft>
            <dtc-button fxHide.lt-sm secondary large (click)="cancelConfirm()">Back</dtc-button>
          </ng-container>

          <ng-container buttonFooterRight>
            <dtc-button
              fxHide.lt-sm
              fxFlex.lt-sm="100"
              [primary]="true"
              type="submit"
              [disabled]="!bindForm.valid"
              [aptTooltip]="bindForm.valid ? '' : 'Please agree to the required options.'"
              tooltipPlacement="top"
              tooltipMaxWidth="matchElm"
              >Confirm and Proceed</dtc-button
            >
          </ng-container>
        </apt-button-footer>

        <div fxHide.gt-xs fxLayout.lt-sm="column" fxLayout.gt-xs="row" ngClass.lt-sm="mobile-confirm-buttons">
          <dtc-button
            fxFlex.lt-sm="100"
            [primary]="true"
            type="submit"
            [disabled]="!bindForm.valid"
            [aptTooltip]="bindForm.valid ? '' : 'Please agree to the required options.'"
            tooltipPlacement="top"
            tooltipMaxWidth="matchElm"
            >Confirm and Proceed</dtc-button
          >

          <dtc-button fxFlex fxHide.gt-xs secondary (click)="cancelConfirm()" [ngStyle]="{ 'margin-top': '20px' }">
            Back</dtc-button
          >
        </div>
      </form>
    </ng-container>
    <ng-container right>
      <payment-summary [editable]="false" [policyStore]="policyStore" [product]="policyStore.product">
      </payment-summary>

      <contact-box [overrideTheme]="d2cTheme" [phoneType]="'phoneD2C'"></contact-box>
    </ng-container>
  </dtc-page-content>
</ng-container>
