import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ThemingService } from '@shared/services/theming.service';
import { Theme } from '@shared/themes/default.theme';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShellHostedComponent implements OnInit {
  constructor(private themeService: ThemingService) {}

  theme: Theme;

  ngOnInit() {
    this.themeService.setTheme('hosted'); //detect and set this from shell for now.

    this.theme = this.themeService.theme;

    // console.log('this.theme', this.theme);
  }
}
