import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

// import { HomeModule } from '../home.module';
import { BillingStore } from '@app/interfaces/billing-store.interface';

@Injectable({ providedIn: 'root' }) //HomeModule
export class BillingStoreService {
  // Make _billingStoresSource private so it's not accessible from the outside,
  // expose it as billingStores$ observable (read-only) instead.
  // Write to _billingStoresSource only through specified store methods below.
  private readonly _billingStoreSource = new BehaviorSubject<BillingStore>({});
  private readonly _storageName: string = 'billingStore';

  // Exposed observable (read-only).
  readonly billingStores$ = this._billingStoreSource.asObservable();

  constructor() {
    this.getBillingStoreFromStorage(); //check for a store in session storage
  }

  // Get last value without subscribing to the billingStores$ observable (synchronously).
  getBillingStore(): BillingStore {
    return this._billingStoreSource.getValue();
  }

  private getBillingStoreFromStorage() {
    this.setBillingStore(JSON.parse(sessionStorage.getItem(this._storageName)));
  }

  private _persistBillingStores(billingStore: BillingStore): void {
    sessionStorage.setItem(this._storageName, JSON.stringify(billingStore));
  }

  setBillingStore(billingStore: BillingStore): void {
    this._billingStoreSource.next(billingStore);
    this._persistBillingStores(billingStore);
  }

  // addBillingStore(billingStore: BillingStore): void {
  //   const billingStores = [...this.getBillingStores(), billingStore];
  //   this._setBillingStores(billingStores);
  // }

  // removeBillingStore(billingStore: BillingStore): void {
  //   const billingStores = this.getBillingStores().filter(p => p.email !== billingStore.email);
  //   this._setBillingStores(billingStores);
  // }
}
