import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-quote-header',
  templateUrl: './quote-header.component.html',
  styleUrls: ['./quote-header.component.scss'],
})
export class QuoteHeaderComponent implements OnInit {
  @Input() insuredName: string;
  @Input() address: string;
  @Input() policyType: string;
  @Input() issuingCompany: string;

  constructor() {}

  ngOnInit(): void {}
}
