import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ThemingService } from '@shared/services/theming.service';
import { Theme } from '@shared/themes/default.theme';
import { AptComponentAidService } from '@archinsurance-da/arch-portal-tools';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'dtc-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class DtcButtonComponent implements OnInit {
  @Input() primary: boolean;
  @Input() secondary: boolean;
  @Input() large: boolean;
  @Input() type: string = 'button';
  @Input() icon: IconDefinition;
  @Input() disabled: boolean;
  @Input() allowDisabledClick: boolean = false;
  @ViewChild('button') button: ElementRef;

  theme: Theme;

  constructor(
    private _elementRef: ElementRef,
    private componentAid: AptComponentAidService,
    private renderer: Renderer2,
    private themeService: ThemingService
  ) {}

  ngOnInit(): void {
    this.primary = this.componentAid.truthyBooleanAttr(this.primary);
    this.secondary = this.componentAid.truthyBooleanAttr(this.secondary);
    if (!this.large && this.primary && !this.secondary) {
      this.large = true;
    }
    this.large = this.componentAid.truthyBooleanAttr(this.large);
    this.disabled = this.componentAid.truthyBooleanAttr(this.disabled);

    this.theme = this.themeService.theme;

    fromEvent(this._elementRef.nativeElement, 'click', {
      //if button is disabled, turn off click
      capture: true,
    }).subscribe((e: MouseEvent) => {
      if (this.disabled && !this.allowDisabledClick) {
        e.stopPropagation();
      }
    });
  }

  private rippleCircle: HTMLElement;

  doRipple(event: any) {
    if (!this.disabled) {
      const button = this.button.nativeElement;

      const buttonRect = button.getBoundingClientRect();

      // console.log('buttonRect', buttonRect);

      const diameter = Math.max(buttonRect.width, buttonRect.height);
      const radius = diameter / 2;

      if (this.rippleCircle) {
        this.renderer.removeChild(button, this.rippleCircle);
      }

      // const scrollPosY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

      this.rippleCircle = this.renderer.createElement('span');
      this.renderer.setStyle(this.rippleCircle, 'width', `${diameter}px`);
      this.renderer.setStyle(this.rippleCircle, 'height', `${diameter}px`);
      this.renderer.setStyle(this.rippleCircle, 'left', `${event.clientX - buttonRect.left - radius}px`);
      this.renderer.setStyle(this.rippleCircle, 'top', `${event.clientY - buttonRect.top - radius}px`);
      this.renderer.addClass(this.rippleCircle, 'ripple');

      this.renderer.appendChild(button, this.rippleCircle);
    }
  }
}
