import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PolicyData } from '@app/interfaces/policy.interface';
import { BillingInfoService } from '@shared/services/billing-info.service';
import { BillingStore } from '@app/interfaces/billing-store.interface';
import { BillingStoreService } from '@app/home/store/billing-store.service';
import { AbstractControl, UntypedFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { AuthenticationService, UserProfile } from '@app/authentication/authentication.service';
import { ThemingService } from '@shared/services/theming.service';
import { Theme } from '@shared/themes/default.theme';
import { constants } from '@app/@shared/constants';

@Component({
  selector: 'app-policy',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
  policyStore: BillingStore = {};

  theme: Theme;

  //quote status, if bound send to payment, if issued show info screen

  constructor(
    private fb: UntypedFormBuilder,
    private billingInfoService: BillingInfoService,
    private billingStoreService: BillingStoreService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private themeService: ThemingService
  ) {}

  get userProfile(): UserProfile {
    return this.authenticationService.userProfile;
  }

  d2cTheme: Theme;

  ngOnInit() {
    this.policyStore = this.billingStoreService.getBillingStore();

    if (!this.policyStore) {
      this.router.navigate(['direct', 'policy']); //go back to beginning if there is no policy in the billing store
    }

    this.themeService.themeChange.subscribe((theme: Theme) => {
      console.log('theme', theme);
      this.theme = theme;
    });

    this.d2cTheme = JSON.parse(JSON.stringify(this.theme));
    if (this.d2cTheme.name != 'appalachian') {
      this.d2cTheme.agent.phone =
        this.policyStore.product == 'ea' ? constants.contactForCanopy : constants.contactForNationwideD2C;
    }
    // console.log('this.theme', this.theme);
  }

  bindForm = this.fb.group({
    affirm: ['', Validators.required],
    autopay: ['', Validators.required],
    legal: ['', Validators.required],
    legal2: ['', Validators.required],
  });

  goToPayment() {
    this.router.navigate(['direct', 'payment']);
  }

  cancelConfirm() {
    this.router.navigate(['direct', 'policy', this.policyStore.product, btoa(this.policyStore.id)]);
  }
}
