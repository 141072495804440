import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { constants } from './../../@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  envName: string = environment.envName;
  idToken: string = localStorage.getItem('id_token').toString();
  constructor(private http: HttpClient) {}

  getRolesAndPortals(): Observable<any> {
    let token = localStorage.getItem('id_token');
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + token);
    return this.http.post<any>(environment.aipHostPort + constants.permissionEndPoint, {}, { headers }).pipe(
      map((result) => {
        return result;
      })
    );
  }

  getMenu(): Observable<any> {
    let token = localStorage.getItem('id_token');
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + token);
    return this.http
      .post<any>(
        environment.aipHostPortMenu + constants.topmenuEndPoint,
        {
          appName: 'AIGI-BasePortal',
          rolesList: ['Menuset_GlobalLandingPage'],
        },
        { headers }
      )
      .pipe(
        map((result: any) => {
          let dataMenu = result.map((data: any) => ({
            text: data.name,
            url: data.action,
          }));

          return dataMenu;
        })
      );
  }
  getSettingMenu(): Array<Object> {
    let idToken = localStorage.getItem('id_token').toString(); //token from local storage
    const helper = new JwtHelperService();
    let decodedJWT = helper.decodeToken(idToken); //decoding it
    //decodedJWT['http://www.archinsurance.com/claims/roles'] = environment.mockRoleData;

    if (decodedJWT) {
      if (decodedJWT.hasOwnProperty('http://www.archinsurance.com/claims/roles')) {
        let envRoles = decodedJWT['http://www.archinsurance.com/claims/roles']; // assign roles key to variable
        let adminMenu: any = [];
        let nonAdminMenu: any = [];
        let agencyMenu: any = [];
        if (envRoles && envRoles.find((role: string) => role.includes(environment.AIP_ADMIN_ROLE))) {
          adminMenu = [
            // {//removing for now due to: https://arch-insurance.atlassian.net/browse/AIP-6002
            //   text: 'My Profile',
            //   action: () => {
            //     this.navigateRoute('/profile');
            //   },
            // },
          ];
        } else {
          nonAdminMenu = [
            // {//removing for now due to: https://arch-insurance.atlassian.net/browse/AIP-6002
            //   text: 'My Profile',
            //   action: () => {
            //     this.navigateRoute('/profile');
            //   },
            // },
          ];
        }

        if (envRoles && envRoles.find((role: string) => role.includes(environment.AIP_AGENCY_ROLE))) {
          agencyMenu = [
            // {
            //   text: `${decodedJWT.name}'s Profile`,
            // },
            // {
            //   text: `${decodedJWT.email}`,
            // },
          ];
        }

        return [...agencyMenu, ...adminMenu, ...nonAdminMenu];
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  navigateRoute(route: string) {
    let url = environment.appUrl + route;
    window.open(url, '_self');
  }

  getUserPermission() {
    let envRoles = this.decodeToken(this.idToken);
    if (envRoles && envRoles.find((role: string) => role.includes(environment.internal))) {
      return 'INTERNAL';
    } else if (envRoles && envRoles.find((role: string) => role.includes(environment.external))) {
      return 'EXTERNAL';
    } else {
      return null;
    }
  }
  decodeToken(idToken: string) {
    const helper = new JwtHelperService();
    let decodedJWT = helper.decodeToken(idToken);
    if (decodedJWT) {
      if (decodedJWT.hasOwnProperty('http://www.archinsurance.com/claims/roles')) {
        //decodedJWT['http://www.archinsurance.com/claims/roles'] = environment.mockRoleData;
        return decodedJWT['http://www.archinsurance.com/claims/roles'];
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  isUserAdmin() {
    let envRoles = this.decodeToken(this.idToken);
    if (envRoles && envRoles.find((role: string) => role.includes(environment.admin))) {
      return 'Y';
    } else {
      return 'N';
    }
  }
}
