import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AuthConfig,
  JwksValidationHandler,
  OAuthModule,
  OAuthModuleConfig,
  OAuthStorage,
  ValidationHandler,
} from 'angular-oauth2-oidc';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { AuthenticationGuard } from '@app/authentication/authentication.guard';
import { AppConfig } from '@app/app.config';

export function authConfigFactory(appConfig: AppConfig): AuthConfig {
  return appConfig.authConfig;
}

export function oAuthModuleConfigFactory(appConfig: AppConfig): OAuthModuleConfig {
  return appConfig.authModuleConfig;
}

export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [CommonModule, OAuthModule.forRoot()],
})
export class AuthenticationModule {
  static forRoot(): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [
        AuthenticationService,
        AuthenticationGuard,
        { provide: AuthConfig, useFactory: authConfigFactory, deps: [AppConfig] },
        { provide: OAuthModuleConfig, useFactory: oAuthModuleConfigFactory, deps: [AppConfig] },
        { provide: OAuthStorage, useFactory: storageFactory },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: AuthenticationModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Authentication module in the AppModule only.`);
    }
  }
}
