import { Injectable } from '@angular/core';
import { BillingStore } from '@app/interfaces/billing-store.interface';

declare let pendo: any;

@Injectable({
  providedIn: 'root',
})
export class PendoService {
  constructor() {}

  track(eventName: string, policyStore?: BillingStore, metaData?: any) {
    try {
      let eventMetadata: any = metaData ? metaData : undefined;
      if (policyStore && policyStore?.policyData?.policy?.guid) {
        eventMetadata = metaData || {};
        eventMetadata.guid = policyStore.policyData.policy.guid;
      } else if (policyStore && policyStore?.policyData?.policy?.policyId) {
        eventMetadata = metaData || {};
        eventMetadata.mcid = policyStore.policyData.policy.policyId;
      }
      if (metaData) {
        pendo.track(eventName, metaData);
      } else {
        pendo.track(eventName);
      }
    } catch (err) {
      console.warn('Unable to send pendo tracking.', eventName, metaData, err);
    }
  }
}
// 'paymentSubmitted'
