import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@shared';
import { DirectRoutingModule } from './direct-routing.module';
import { PolicyComponent } from './pages/policy/policy.component';
import { ConfirmComponent } from './pages/confirm/confirm.component';
import {
  AptButtonModule,
  AptFormModule,
  AptLayoutModule,
  AptTypographyModule,
  AptTemplatesModule,
  AptDialogModule,
  AptIframeModule,
  AptTableModule,
  AptLoadersModule,
  AptTextLabelModule,
  AptTooltipModule,
  AptInformModule,
  AptStateBlockModule,
  AptNavigationModule,
} from '@archinsurance-da/arch-portal-tools';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SuccessComponent } from './pages/success/success.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { PageContentComponent } from './components/page-content/page-content.component';
import { DtcButtonComponent } from './components/button/button.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BreadCrumbsComponent } from './components/bread-crumbs/bread-crumbs.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FontAwesomeModule,
    AptButtonModule,
    AptFormModule,
    AptLayoutModule,
    AptLoadersModule,
    AptTextLabelModule,
    AptTypographyModule,
    AptTemplatesModule,
    AptDialogModule,
    AptIframeModule,
    AptTableModule,
    AptInformModule,
    AptTooltipModule,
    AptNavigationModule,
    AptStateBlockModule,
    DirectRoutingModule,
  ],
  declarations: [
    PolicyComponent,
    ConfirmComponent,
    SuccessComponent,
    PaymentComponent,
    PageContentComponent,
    DtcButtonComponent,
    BreadCrumbsComponent,
  ],
})
export class DirectModule {}
