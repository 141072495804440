import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { constants } from './@shared/constants';
import { Logger, untilDestroyed } from '@core';
import { I18nService } from '@app/i18n';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { AppConfig } from '@app/app.config';
import { AptFeatureFlagService, MaintenanceSchedule } from '@archinsurance-da/arch-portal-tools';
import { MaintenanceSheduleService } from './@shared/services/maintenance-schedule.service';

const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private appConfig: AppConfig,
    private authenticationService: AuthenticationService,
    private ffService: AptFeatureFlagService,
    private maintSchedService: MaintenanceSheduleService
  ) {}

  ngOnInit() {
    this.ffService.register('Payment Portal', 'payment_portal', environment.LAUNCHDARKLY_CLIENTID);

    // Setup logger here
    if (environment.production) {
      Logger.enableProductionMode();
    }

    if (
      !location.pathname.includes('direct/') &&
      !location.pathname.includes('hosted/') &&
      !location.pathname.includes('renewal/')
    ) {
      this.authenticationService.runInitialLoginSequence();
    } else {
      this.authenticationService.initializePendo(); //anonymous tracking
    }

    this.ffService.flagChange.subscribe((flags: any) => {
      // console.log('environment.environmentName', environment.environmentName);
      const maintenanceSchedule: MaintenanceSchedule[] = !flags.maintenanceSchedule?.current
        ? flags.maintenanceSchedule
        : flags.maintenanceSchedule.current;

      this.maintSchedService.setSchedule(maintenanceSchedule);
    });

    // log.debug('init');

    // Setup translations
    this.i18nService.init(this.appConfig.defaultLanguage, this.appConfig.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }
}
