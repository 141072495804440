import { DefaultDirectTheme } from '../default.theme';
import { constants } from '../../constants';
export class CanopyTheme extends DefaultDirectTheme {
  header: string = 'Contact an Agent';
  name: string = 'canopy';
  // logo: string = 'logo-progressive.svg';
  // logoSquare: string = 'nwide_logo_square.png';

  phone: '(888) 910-6553';
  phoneD2C: '(888) 910-6553';

  agent: any = {
    header: 'Agent Information',
    name: '',
    phone: '(888) 910-6553',
    phoneD2C: '(888) 910-6553',
    email: '',
  };
  primaryColor: string = '#2D95E5'; //inherited from default

  constructor() {
    super();
  }
}
