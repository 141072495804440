import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@shared';
import { RenewalRoutingModule } from './renewal-routing.module';
import { RenewalComponent } from './pages/renewal/renewal.component';
import {
  AptButtonModule,
  AptFormModule,
  AptLayoutModule,
  AptTypographyModule,
  AptTemplatesModule,
  AptDialogModule,
  AptIframeModule,
  AptTableModule,
  AptLoadersModule,
  AptTextLabelModule,
  AptTooltipModule,
  AptInformModule,
  AptStateBlockModule,
  AptNavigationModule,
} from '@archinsurance-da/arch-portal-tools';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SuccessComponent } from './pages/success/success.component';
import { DtcButtonComponent } from './components/button/button.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FontAwesomeModule,
    AptButtonModule,
    AptFormModule,
    AptLayoutModule,
    AptLoadersModule,
    AptTextLabelModule,
    AptTypographyModule,
    AptTemplatesModule,
    AptDialogModule,
    AptIframeModule,
    AptTableModule,
    AptInformModule,
    AptTooltipModule,
    AptNavigationModule,
    AptStateBlockModule,
    RenewalRoutingModule,
  ],
  declarations: [RenewalComponent, SuccessComponent, DtcButtonComponent],
})
export class RenewalModule {}
